import { Component } from 'react';
import firebase from 'firebase';

export default class RoutesAndLocationsListener extends Component {
  constructor(...args) {
    super(...args);

    this.daemon = null;

    // Bindings
    this.onLocationsUpdate = this.onLocationsUpdate.bind(this);
    this.onRoutesUpdate = this.onRoutesUpdate.bind(this);
  }

  componentDidMount() {
    this.startListening();
  }

  componentWillUnmount() {
    this.stopListening();
  }

  onLocationsUpdate(snapshot) {
    const { onLocationsUpdate = () => {} } = this.props;
    onLocationsUpdate(snapshot.val() || {});
  }

  onRoutesUpdate(snapshot) {
    const { onRoutesUpdate = () => {} } = this.props;
    onRoutesUpdate(snapshot.val() || {});
  }

  startListening() {
    if (this.daemon) {
      return;
    }

    // Listen for locations changes
    firebase
      .database()
      .ref('/public/locations')
      .on('value', this.onLocationsUpdate);

    // Listen for routes changes
    firebase
      .database()
      .ref('/public/routes')
      .on('value', this.onRoutesUpdate);

    // Start refresh daemon
    const refreshRoutes = () => {
      firebase
        .database()
        .ref('/public/triggers/refreshRoutes')
        .set(Date.now());
    };
    refreshRoutes();
    this.daemon = setInterval(refreshRoutes, 3 * 60 * 60 * 1000);
  }

  stopListening() {
    if (this.daemon) {
      clearInterval(this.daemon);
      this.daemon = null;
    }
    // Stop listening for locations changes
    firebase
      .database()
      .ref('/public/locations')
      .off('value', this.onLocationsUpdate);

    // Stop listening for routes changes
    firebase
      .database()
      .ref('/public/routes')
      .off('value', this.onRoutesUpdate);
  }

  render() {
    return null;
  }
}
