import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import {
  DefaultScreen, Button, ElementGroup, Element,
} from '../theme';
import { withWidgetTitleFont } from '../theme/Fonts';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('ForgotPasswordLinkSentPageUI');

const ForgotPasswordLinkSentPageUI = ({ onBack = () => {} }) => (
  <DefaultScreen title={t('Reset your password')} onBack={onBack}>
    <ElementGroup>
      <Element>
        <div
          style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Header icon style={withWidgetTitleFont({ marginTop: '5%', marginBottom: '5%' })}>
            <Icon name="check" />
            {t('Done.')}
            <br />
            {t("You'll receive a link shortly.")}
          </Header>
          <Button onClick={onBack} fluid primary>
            {t('Back')}
          </Button>
        </div>
      </Element>
    </ElementGroup>
  </DefaultScreen>
);

export default ForgotPasswordLinkSentPageUI;
