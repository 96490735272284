import React from 'react';
import { Redirect } from 'react-router-dom';
import GenericTripsListPageUI from '../../../ui/pages/GenericTripsListPageUI';

const GenericTripListPage = ({
  origin,
  destination,
  date,
  selectedTrip = null,
  ridesCache,
  isReturn = false,
  numberOfPassengers,
  onTripSelect = () => {},
  history,
}) => {
  if (!origin || !destination || !date) {
    return <Redirect to="/book/" />;
  }

  const path = `${origin}/${destination}/${date}`;
  const rides = ridesCache[path] || null;
  const onBack = () => {
    history.goBack();
  };

  return (
    <GenericTripsListPageUI
      {...{
        origin,
        destination,
        date,
        selectedTrip,
        rides,
        isReturn,
        onTripSelect,
        onBack,
        numberOfPassengers,
      }}
    />
  );
};

export default GenericTripListPage;
