import React from 'react';
import Page from './Page';
import TopBar from './TopBar';

const DefaultScreen = ({
  title,
  onBack,
  children,
  topBarChildren = null,
  topBarRightChildren = null,
}) => (
  <Page>
    <TopBar
      title={title}
      leftIcon={onBack ? 'arrow left' : null}
      onLeftIconClick={onBack}
      rightChildren={topBarRightChildren}
    >
      {topBarChildren}
    </TopBar>
    {children}
  </Page>
);

export default DefaultScreen;
