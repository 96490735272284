import React from 'react';
import EditPassengersPageUI from '../../ui/pages/EditPassengersPageUI';

const PassengersDetailsPage = (props) => {
  const {
    appState: {
      user: { savedPassengers = {}, passengerId },
      session: { passengerDetails = [] },
    },
    history,
    setUserState = () => {},
    setSession = () => {},
  } = props;

  const pDetails = passengerDetails || [];
  const savedP = Object.values(savedPassengers);

  const onPassengerDelete = async (p) => {
    console.log('Asked to delete passenger with id: ', p.id);
    const newSavedPassengers = Object.assign({}, savedPassengers);
    delete newSavedPassengers[p.id];

    console.log('New passengers: ', newSavedPassengers);

    await setUserState({
      savedPassengers: newSavedPassengers,
    });
    await setSession({ passengerDetails: pDetails.filter(s => s.id !== p.id) });
  };

  return (
    <EditPassengersPageUI
      userPassengerId={passengerId}
      savedPassengers={savedP}
      onPassengerDelete={onPassengerDelete}
      addPassengerLink="/settings/passengers/add"
      onBack={history.goBack}
    />
  );
};
export default PassengersDetailsPage;
