import React, { Fragment } from 'react';
import moment from 'moment';
import formatNumber from 'format-number';
import { Image } from 'semantic-ui-react';
import ucfirstEachWord from '../../../lib/ucfirstEachWord';
import { discreetTextColor } from './Colors';
import { withBoldTitleFont, withWidgetSubtitleFont } from './Fonts';
import SquaredText from './SquaredText';

const formatPrice = price => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const TicketDetails = ({ ticketDetails, nPassengers = 1 }) => {
  const departure = moment.utc(ticketDetails.departureTime);
  const arrival = moment.utc(ticketDetails.arrivalTime);
  // const rideDuration = moment.duration(arrival.diff(departure));
  // const formattedDuration = `${rideDuration.hours()}h ${rideDuration.minutes()}m`;
  const nDaysAdded = moment
    .duration(
      moment.utc(arrival.format('YYYY-MM-DD')).diff(moment.utc(departure.format('YYYY-MM-DD'))),
    )
    .days();
  const d = nDaysAdded ? ` (+${nDaysAdded}d)` : '';
  const dh = departure.format('HH:mm');
  const ah = arrival.format('HH:mm');
  // const fp = (nPassengers > 1 ? `${nPassengers} x ` : '') + formatPrice(ticketDetails.grossPrice);
  const dt = ucfirstEachWord(ticketDetails.originTerminal.name);
  const at = ucfirstEachWord(ticketDetails.destinationTerminal.name);
  const companyLogo = ticketDetails.transporter.logo;
  const companyName = ucfirstEachWord(ticketDetails.transporter.name);
  const departureDate = departure.format('LL');

  // const HighLight = ({ children }) => <span style={withBoldTitleFont({})}>{children}</span>;
  // const Details = ({ children }) => <span style={withWidgetSubtitleFont({})}>{children}</span>;

  return (
    <div>
      <div style={{ color: discreetTextColor, fontSize: '0.9em', marginBottom: 10 }}>
        {departureDate}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: 15,
          }}
        >
          <div style={{ fontSize: '1rem', color: '#3176D4', textAlign: 'center' }}>{dh}</div>
          <div
            style={{
              flex: '1',
              borderLeft: 'dashed 2px #909B9D',
              marginTop: 5,
              marginBottom: 5,
            }}
          />
          <div style={{ fontSize: '1rem', color: '#3176D4', textAlign: 'center' }}>
            {ah}
            {d && (
              <Fragment>
                <br />
                <span style={{ color: discreetTextColor, fontSize: '0.8em' }}>{d}</span>
              </Fragment>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'space-between',
            marginRight: 15,
            flex: '1',
          }}
        >
          <div>{dt}</div>
          <div style={{ color: discreetTextColor, fontSize: '0.8em' }}>
            <SquaredText style={{ marginLeft: 0, marginTop: 8, marginBottom: 8 }}>
              {ticketDetails.apiData.trp_desc}
            </SquaredText>
          </div>
          <div>
            {at}
            {d && (
              <Fragment>
                <br />
                <span style={{ color: discreetTextColor, fontSize: '0.8em' }}>&nbsp;</span>
              </Fragment>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '30%',
            textAlign: 'center',
          }}
        >
          <Image src={companyLogo} size="mini" />
          <div style={{ fontSize: '0.7rem', textAlign: 'center' }}>{companyName}</div>
          {d && <span style={{ color: discreetTextColor, fontSize: '0.8em' }}>&nbsp;</span>}
        </div>
      </div>
    </div>
  );
  /*


  return (
    <table style={{ width: '100%' }}>
      <tr>
        <td>
          <Dot />
        </td>
        <td>
          <HighLight>{dh}</HighLight>
        </td>
        <td>
          <HighLight>{dt}</HighLight>
        </td>
        <td />
      </tr>
      <tr>
        <td>
          <div />
        </td>
        <td>
          {' '}
          <Image src={companyLogo} avatar />
        </td>
        <td>
          <Details>{formattedDuration}</Details>
        </td>
        <td style={{ textAlign: 'right' }}>
          <HighLight>{fp}</HighLight>
        </td>
      </tr>
      <tr>
        <td>
          <Dot />
        </td>
        <td>
          <HighLight>{ah + d}</HighLight>
        </td>
        <td>
          <HighLight>{at}</HighLight>
        </td>
        <td />
      </tr>
    </table>
    );
    */
};

export default TicketDetails;
