import React, { Fragment, Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import {
  Form, Message, Dropdown, Input,
} from 'semantic-ui-react';
import isEmailValid from '@seafront/is-email-valid';
import formatNumber from 'format-number';
import check from 'check-types';
import PhoneInput from '../../vendors/react-phone-input-2-master/src';
import '../../vendors/react-phone-input-2-master/src/style.css';

import {
  DefaultScreen, Element, ElementGroup, Button,
} from '../theme';
import { withDefaultFont } from '../theme/Fonts';
import { translatorForNamespace } from '../../../lib/TranslationManager';
import { COUNTRY_OPTIONS } from '../../../config/countriesData';

const formatPrice = price => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);
const t = translatorForNamespace('CreditCardPaymentPageUI');

class CreditCardPaymentPageUI extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);

    this.state = {
      firstName: '',
      lastName: '',
      ssn: '',
      country: null,
      phone: '',
      selectedPassenger: null,
    };
  }

  onUserPassengerDataChange(key, value) {
    this.setState({ [key]: value });
  }

  isCedulaValid() {
    const { ssn, country } = this.state;

    if (country === 'ec') {
      return /^[0-9]{10}$/.test(ssn);
    }

    return /^[0-9A-Z-]+$/.test(ssn);
  }

  isUserPassengerDataValid() {
    const {
      firstName, lastName, ssn, country, selectedPassenger,
    } = this.state;
    const { savedPassengers, passengerId } = this.props;

    if (passengerId) {
      if (!savedPassengers.find(p => p.id === passengerId)) {
        return false;
      }
      return true;
    }

    if (!selectedPassenger) {
      return false;
    }

    if (selectedPassenger === 'other') {
      if (!check.nonEmptyString(firstName.trim())) {
        return false;
      }
      if (!check.nonEmptyString(lastName.trim())) {
        return false;
      }
      if (!country || !check.nonEmptyString(country.trim())) {
        return false;
      }
      if (!check.nonEmptyString(ssn.trim())) {
        return false;
      }
      if (!this.isCedulaValid()) {
        return false;
      }
    } else if (savedPassengers.filter(p => p.id === selectedPassenger).length === 0) {
      return false;
    }

    return true;
  }

  async submit() {
    const {
      email,
      stripe,
      setUserPassengerId = () => {},
      setUserPassengerDetails = () => {},
      onToken = () => {},
      passengerId,
    } = this.props;
    const {
      firstName, lastName, ssn, country, phone, selectedPassenger,
    } = this.state;

    if (!passengerId) {
      if (selectedPassenger === 'other') {
        await setUserPassengerDetails({
          firstName,
          lastName,
          ssn,
          country,
          phone,
        });
      } else {
        await setUserPassengerId(selectedPassenger);
      }
    }

    const { token } = await stripe.createToken({ name: email });
    // console.log(`Stripe token generated: ${token}`);
    onToken(email, token);
  }

  render() {
    const {
      payAmount,
      error,
      email,
      loggedIn,
      savedPassengers,
      passengerId,
      onEmailChange = () => {},
      onBack = () => {},
    } = this.props;
    const {
      firstName, lastName, ssn, country, phone, selectedPassenger,
    } = this.state;

    const canSubmit = isEmailValid(email) && this.isUserPassengerDataValid();
    const elementsOptions = {
      style: {
        base: {
          fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
          margin: '0',
          outline: '0',
          WebkitAppearance: 'none',
          tapHighlightColor: 'rgba(255,255,255,0)',
          lineHeight: '1.21428571em',
          padding: '.67857143em 1em',
          fontSize: '1em',
          background: '#fff',
          border: '1px solid rgba(34,36,38,.15)',
          color: 'rgba(0,0,0,.87)',
          borderRadius: '.28571429rem',
          boxShadow: '0 0 0 0 transparent inset',
          transition: 'color .1s ease,border-color .1s ease',
        },
        invalid: {
          color: '#c23d4b',
        },
      },
    };

    return (
      <DefaultScreen title={t('Payment')} onBack={onBack}>
        {!passengerId && (
          <Fragment>
            <ElementGroup title={t('Tell us more about yourself')}>
              <Element>
                <Form>
                  <Form.Field>
                    <label style={withDefaultFont({})}>{t('Which passenger are you?')}</label>
                    <Dropdown
                      placeholder={t('Select Passenger')}
                      fluid
                      search
                      selection
                      options={[
                        ...savedPassengers.map(p => ({
                          key: p.id,
                          value: p.id,
                          icon: 'user',
                          text: `${p.firstName} ${p.lastName}`,
                        })),
                        {
                          key: 'other',
                          value: 'other',
                          icon: 'question',
                          text: t("I'm not one of the passengers"),
                        },
                      ]}
                      onChange={(e, o) => this.onUserPassengerDataChange('selectedPassenger', o.value)
                      }
                      value={selectedPassenger}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label style={withDefaultFont({})}>{t('Your E-mail')}</label>
                    <Form.Input
                      placeholder={t('Your E-mail')}
                      type="email"
                      value={email || ''}
                      onChange={(e, o) => onEmailChange(o.value)}
                    />
                  </Form.Field>

                  {selectedPassenger === 'other' && (
                    <Fragment>
                      <Form.Field style={{ marginTop: 25 }}>
                        <label style={withDefaultFont({})}>{t('Your Name')}</label>
                        <Form.Input
                          fluid
                          placeholder={t('First name')}
                          value={firstName || ''}
                          onChange={(e, o) => this.onUserPassengerDataChange('firstName', o.value)}
                          style={{ marginBottom: 0 }}
                        />
                        <Form.Input
                          fluid
                          placeholder={t('Last name')}
                          value={lastName || ''}
                          onChange={(e, o) => this.onUserPassengerDataChange('lastName', o.value)}
                          style={{
                            marginTop: 0,
                          }}
                        />
                      </Form.Field>

                      <Form.Field style={{ marginTop: 25 }}>
                        <label style={withDefaultFont({})}>{t('Your Mobile phone')}</label>
                        <PhoneInput
                          placeholder={t('Mobile phone')}
                          defaultCountry="ec"
                          value={phone}
                          onChange={(v, { countryCode }) => {
                            this.onUserPassengerDataChange('phone', v);
                            const { country: c } = this.state;
                            if (!c) {
                              this.onUserPassengerDataChange('country', countryCode);
                            }
                          }}
                          inputStyle={{
                            width: '100%',
                            height: 38,
                            marginTop: 10,
                            marginBottom: 10,
                            borderColor: '#22242626',
                            borderRadius: '5px',
                            paddingLeft: 50,
                          }}
                        />
                      </Form.Field>

                      <Form.Field style={{ marginTop: 25 }}>
                        <label style={withDefaultFont({})}>
                          {t('Your identification details')}
                        </label>
                        <Form.Dropdown
                          placeholder={t('Country')}
                          fluid
                          search
                          selection
                          options={COUNTRY_OPTIONS}
                          value={country || 'ec'}
                          onChange={(e, o) => this.onUserPassengerDataChange('country', o.value)}
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        />
                        <Input
                          error={check.nonEmptyString(ssn) && !this.isCedulaValid()}
                          fluid
                          placeholder={
                            !country || country === 'ec' ? t('Cedula') : t('Passport number')
                          }
                          value={ssn || ''}
                          onChange={(e, o) => this.onUserPassengerDataChange('ssn', o.value)}
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        />
                      </Form.Field>
                    </Fragment>
                  )}
                </Form>
              </Element>
            </ElementGroup>
          </Fragment>
        )}

        <ElementGroup title={t('Payment details')}>
          <Element>
            <Form>
              <Form.Field>
                <label style={withDefaultFont({})}>{t('Your payment card')}</label>
                <div
                  style={{
                    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                    marginTop: 5,
                    marginBottom: 20,
                    outline: '0',
                    WebkitAppearance: 'none',
                    tapHighlightColor: 'rgba(255,255,255,0)',
                    lineHeight: '1.21428571em',
                    padding: '.67857143em 1em',
                    fontSize: '1em',
                    background: '#fff',
                    border: '1px solid rgba(34,36,38,.15)',
                    color: 'rgba(0,0,0,.87)',
                    borderRadius: '.28571429rem',
                    boxShadow: '0 0 0 0 transparent inset',
                    transition: 'color .1s ease,border-color .1s ease',
                  }}
                >
                  <CardElement {...elementsOptions} />
                </div>
              </Form.Field>
              {error && (
                <Message negative>
                  <Message.Header>{t('There was an error with your payment')}</Message.Header>
                  <p>{error.message}</p>
                </Message>
              )}
              <Button disabled={!canSubmit} primary={!error} fluid onClick={this.submit}>
                {!payAmount && t('Pay now')}
                {payAmount > 0
                  && (error
                    ? t('Retry payment of {amount}', { amount: formatPrice(payAmount) })
                    : t('Pay {amount}', { amount: formatPrice(payAmount) }))}
              </Button>
            </Form>
          </Element>
        </ElementGroup>
      </DefaultScreen>
    );
  }
}

export default injectStripe(CreditCardPaymentPageUI);
