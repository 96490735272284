import React, { Component } from 'react';
import firebase from 'firebase';
import isNative from '../../lib/is-native';
import Busea from './Busea';

class BuseaAppWrapper extends Component {
  constructor(...args) {
    super(...args);

    // Bindings
    this.signIn = this.signIn.bind(this);

    // If we're running on native,
    // add a hook to enable ReactNative to start us
    if (isNative()) {
      window.startBusea = () => {
        this.start();
      };

      window.buseaNativeHooks = {
        signIn: this.signIn,
      };
    }

    // If we're not, we can start
    this.state = {
      canStart: !isNative() || (window && window.buseaCanStart),
    };
  }

  componentDidMount() {
    if (isNative()) {
      // Indicate to ReactNative that this wrapper is loaded
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'wrapper-loaded' }));
    }
  }

  async signIn(authResponse, callback = () => {}) {
    if (!authResponse) {
      return;
    }
    const {
      provider, success = false, credentials = null, error = null,
    } = authResponse;

    if (!success || !credentials) {
      console.error(
        `ERROR received from Native Auth (${provider}): `,
        credentials ? error : 'No credentials received',
      );
      return;
    }

    let fbCredentials = null;
    switch (provider) {
      case 'facebook':
        fbCredentials = firebase.auth.FacebookAuthProvider.credential(credentials);
        await firebase.auth().signInWithCredential(fbCredentials);
        break;
      case 'google':
        {
          const { accessToken, idToken } = credentials;
          fbCredentials = firebase.auth.GoogleAuthProvider.credential(idToken, accessToken);
          await firebase.auth().signInWithCredential(fbCredentials);
        }
        break;
      case 'password':
        {
          const { email, password } = credentials;
          try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
          } catch (err) {
            console.log(err);
          }
        }
        break;
      default:
        // Do nothing
        break;
    }

    callback();
  }

  start() {
    this.setState({ canStart: true });
  }

  render() {
    const { canStart } = this.state;
    if (!canStart) {
      return '';
    }
    return <Busea />;
  }
}

export default BuseaAppWrapper;
