import React from 'react';
import { Redirect } from 'react-router-dom';
import GenericTripStopsPage from './shared/GenericTripStopsPage';

const ReturnTripStopsPage = ({
  appState: {
    session: {
      selectedDeparture, selectedArrival, returnDate, returnTrip,
    },
    cache: { rides = [] },
  },
  history,
}) => {
  if (!returnDate) {
    return <Redirect to="/book/return/select/" />;
  }

  return (
    <GenericTripStopsPage
      origin={selectedArrival}
      destination={selectedDeparture}
      date={returnDate}
      selectedTrip={returnTrip}
      isReturn={false}
      ridesCache={rides}
      history={history}
    />
  );
};

export default ReturnTripStopsPage;
