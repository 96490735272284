import React from 'react';
import { Menu } from 'semantic-ui-react';
import { widgetBorderColor, widgetBackgroundColor } from './Colors';
import { withDefaultFont } from './Fonts';
import TitleBlock from './TitleBlock';

const ElementGroup = ({
  title, subtitle, children, slim = false,
}) => {
  const parsedStyle = withDefaultFont({
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: widgetBackgroundColor,
    borderTop: `solid 1px${widgetBorderColor}`,
    borderBottom: `solid 1px${widgetBorderColor}`,
    marginBottom: '3px',
    marginTop: 6,
  });

  if (slim) {
    Object.assign(parsedStyle, {
      marginBottom: 0,
      marginTop: 0,
    });
  }

  return (
    <TitleBlock title={title} subtitle={subtitle} slim={slim}>
      <Menu fluid vertical style={parsedStyle}>
        {children}
      </Menu>
    </TitleBlock>
  );
};

export default ElementGroup;
