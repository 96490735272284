import React, { Fragment } from 'react';
import ExpandedRideListener from '../listeners/ExpandedRideListener';
import GenericTripDetailsPageUI from '../../ui/pages/GenericTripDetailsPageUI';

const OutTripDetailsPage = ({
  appState: {
    session: {
      selectedDeparture,
      selectedArrival,
      outDate,
      outboundTrip,
      numberOfPassengers,
      returnDate,
    },
  },
  history,
}) => (
  <Fragment>
    <ExpandedRideListener rideId={outboundTrip.id} />
    <GenericTripDetailsPageUI
      origin={selectedDeparture}
      destination={selectedArrival}
      date={outDate}
      trip={outboundTrip}
      isReturn={false}
      isOneWayTrip={!returnDate}
      numberOfPassengers={numberOfPassengers}
      showSeatsLink="/book/out/select/seats/"
      showStopsLink="/book/out/select/stops/"
      confirmLink="/book/return/"
      onBack={history.goBack}
    />
  </Fragment>
);

export default OutTripDetailsPage;
