import React from 'react';

import {
  PopupScreen,
  LoadingPlaceHolder,
  Element,
  ElementGroup,
  Bottom,
  Button,
  Container,
  Spacer,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('GenericStopsPageUI');

const GenericStopsPageUI = ({
  loading, ticket, stops, onBack = () => {},
}) => {
  let content = null;

  if (loading) {
    content = <LoadingPlaceHolder />;
  } else {
    stops.sort((a, b) => a.sortOrder - b.sortOrder);

    content = (
      <ElementGroup
        title={t('Route used by this bus')}
        subtitle={t(
          "This bus passes through all these cities. It doesn't necessarily stops there.",
        )}
      >
        {stops.map(s => (
          <Element key={`ticket-stop_${ticket.id}_${s.name}`}>{s.name}</Element>
        ))}
      </ElementGroup>
    );
  }

  return (
    <PopupScreen title={t('Bus route')} onBack={onBack}>
      {content}
      <Spacer />
      <Bottom>
        <Container>
          <Button onClick={onBack} fluid>
            {t('Back')}
          </Button>
        </Container>
      </Bottom>
    </PopupScreen>
  );
};

export default GenericStopsPageUI;
