import React from 'react';
import { Form, Message } from 'semantic-ui-react';

import {
  DefaultScreen, Element, ElementGroup, Button,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('ForgotPasswordPageUI');

const ForgotPasswordPageUI = ({
  error,
  onBack = () => {},
  email,
  onEmailChange = () => {},
  onResetPassword = () => {},
}) => (
  <DefaultScreen title={t('Reset your password')} onBack={onBack}>
    <ElementGroup>
      <Element>
        <Form onSubmit={() => onResetPassword(email)} error={!!error}>
          {!!error && <Message error header={t('Error')} content={error.message} />}

          <Form.Field>
            <Form.Input
              placeholder={t('E-mail')}
              value={email}
              onChange={(e, o) => onEmailChange(o.value)}
            />
          </Form.Field>
          <Button fluid type="submit" primary>
            {t('Reset password')}
          </Button>
        </Form>
      </Element>
    </ElementGroup>
  </DefaultScreen>
);

export default ForgotPasswordPageUI;
