import { Component } from 'react';
import firebase from 'firebase';

export default class AuthListener extends Component {
  constructor(...args) {
    super(...args);

    this.unregisterAuthObserver = null;
  }

  componentDidMount() {
    this.startListening();
  }

  componentDidUpdate(prevProps) {
    const { online: prevOnline } = prevProps;
    const { online: newOnline } = this.props;

    if (newOnline !== prevOnline) {
      if (newOnline) {
        this.startListening();
      } else {
        this.stopListening();
      }
    }
  }

  componentWillUnmount() {
    this.stopListening();
  }

  startListening() {
    console.log('🔑 [AuthListener]: Start listening...');
    const { onAuthStateChange = () => {} } = this.props;

    if (this.unregisterAuthObserver) {
      return;
    }

    // On mount, start listening to authChanged events
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(onAuthStateChange);
  }

  stopListening() {
    console.log('🔑 [AuthListener]: Stop listening...');
    if (this.unregisterAuthObserver) {
      this.unregisterAuthObserver();
      this.unregisterAuthObserver = null;
    }
  }

  render() {
    return null;
  }
}
