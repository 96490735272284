import React, { Fragment } from 'react';

const Bottom = ({ noSpacer = false, children }) => (
  <Fragment>
    {!noSpacer && <div style={{ flex: '1' }} />}
    <div
      style={{
        minWidth: '100%',
        margin: 0,
        padding: 0,
        // position: 'fixed',
        // bottom: '5%',
        // left: 0,
        // right: 0,
        marginTop: '5%',
        marginBottom: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {children}
    </div>
  </Fragment>
);

export default Bottom;
