import React from 'react';
import withTicketFromUrl from './shared/withTicketFromUrl';
import TicketDetailsPageUI from '../../ui/pages/TicketDetailsPageUI';

const TicketDetailsPage = ({ ticket, history }) => (
  <TicketDetailsPageUI
    {...{
      ticket,
      onBack: () => {
        history.push('/orders/');
      },
    }}
  />
);
export default withTicketFromUrl(TicketDetailsPage);
