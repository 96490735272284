import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase';
import check from 'check-types';
import queryString from 'query-string';
import { withLastLocation } from 'react-router-last-location';
import isNative from '../../../../lib/is-native';
import LoginPageUI from '../../../ui/pages/LoginPageUI';
import SignUpPageUI from '../../../ui/pages/SignUpPageUI';
import ProcessingPayementLoaderPageUI from '../../../ui/pages/ProcessingPayementLoaderPageUI';

class SignInOrSignUpPage extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      error: null,
      email: this.getCurrentAppEmail(),
      password: null,
    };

    // Bindings
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onFacebookSignIn = this.onFacebookSignIn.bind(this);
    this.onGoogleSignIn = this.onGoogleSignIn.bind(this);
  }

  onEmailChange(email) {
    this.setState({ email: email.trim() });
  }

  onPasswordChange(password) {
    this.setState({ password });
  }

  async onLogin() {
    const { email, password } = this.state;
    const { next, history, setSession = () => {} } = this.props;

    await setSession({ isSigningIn: true });
    await this.setState({ error: null });

    try {
      await this.signInOrSignUp(email, password);
      if (next) {
        history.push(next);
      } else {
        history.goBack();
      }

      // On ReactNative, simply save the credentials when we're sure they're good
      if (isNative()) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'sign-in',
            provider: 'password',
            email,
            password,
          }),
        );
      }
    } catch (err) {
      await this.setState({ error: err });
      await setSession({ isSigningIn: false });
    }
  }

  async onFacebookSignIn() {
    // Save last location to return to it once logged in
    const { lastLocation, setSession = () => {} } = this.props;
    if (lastLocation && check.nonEmptyString(lastLocation.pathname)) {
      localStorage['busea.lastLocationBeforeLogin'] = lastLocation.pathname;
    }

    await setSession({ isSigningIn: true });

    if (!isNative()) {
      // On web, redirect
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope('email');
      // provider.addScope('user_birthday');
      firebase.auth().signInWithRedirect(provider);
    } else {
      // On ReactNative, call wrapper to login via Facebook Mobile SDK
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'sign-in', provider: 'facebook' }),
      );
    }
  }

  async onGoogleSignIn() {
    // Save last location to return to it once logged in
    const { lastLocation, setSession = () => {} } = this.props;
    if (lastLocation && check.nonEmptyString(lastLocation.pathname)) {
      localStorage['busea.lastLocationBeforeLogin'] = lastLocation.pathname;
    }
    await setSession({ isSigningIn: true });

    if (!isNative()) {
      // On web, redirect
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      firebase.auth().signInWithRedirect(provider);
    } else {
      // On ReactNative, call wrapper to login via Facebook Mobile SDK
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'sign-in', provider: 'google' }),
      );
    }
  }

  getCurrentAppEmail() {
    const { appState } = this.props;
    let email;
    if (appState.user && check.nonEmptyString(appState.user.email)) {
      ({ email } = appState.user);
    }

    return email;
  }

  async setState(state) {
    return new Promise(resolve => super.setState(state, resolve));
  }

  async signIn(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  async signInOrSignUp(email, password) {
    try {
      await this.signIn(email, password);
    } catch (err) {
      if (err.code === 'auth/user-not-found') {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
      }
      throw err;
    }
  }

  render() {
    const { error = null, email, password } = this.state;
    const {
      type,
      appState: {
        user: { loggedIn = false },
        session: { isSigningIn = false },
      },
      lastLocation,
      history,
      onBack = null,
    } = this.props;
    const UIComponent = type === 'login' ? LoginPageUI : SignUpPageUI;

    console.log('lastLocation = ', lastLocation);

    if (loggedIn) {
      if (check.nonEmptyString(localStorage['busea.lastLocationBeforeLogin'])) {
        const location = localStorage['busea.lastLocationBeforeLogin'];
        localStorage['busea.lastLocationBeforeLogin'] = null;
        return <Redirect to={location} />;
      }
      return <Redirect to="/" />;
    }

    if (isSigningIn) {
      return <ProcessingPayementLoaderPageUI />;
    }

    let goBack = onBack || history.goBack;
    if (window && window.location && check.nonEmptyString(window.location.search)) {
      const parsedQuery = queryString.parse(window.location.search);
      if (check.nonEmptyString(parsedQuery.back)) {
        goBack = () => history.push(parsedQuery.back);
      }
    }

    return (
      <UIComponent
        error={error}
        email={email}
        password={password}
        onEmailChange={this.onEmailChange}
        onPasswordChange={this.onPasswordChange}
        onSubmit={this.onLogin}
        onFacebookSignIn={this.onFacebookSignIn}
        onGoogleSignIn={this.onGoogleSignIn}
        onBack={goBack}
      />
    );
  }
}

export default withLastLocation(SignInOrSignUpPage);
