import React from 'react';
import { Menu } from 'semantic-ui-react';
import { withDefaultFont } from './Fonts';

const Element = ({
  children, style, slim = false, slimmer = false, ...rest
}) => {
  const parsedStyle = Object.assign(
    withDefaultFont(
      Object.assign(
        {
          border: 'none',
          borderRadius: 0,
          boxShadow: 0,
          /* backgroundColor: widgetBackgroundColor, */
          paddingLeft: '5%',
          paddingRight: '5%',
        },
        style,
      ),
    ),
  );

  if (slim) {
    Object.assign(parsedStyle, {
      paddingTop: 5,
      paddingBottom: 5,
    });
  }
  if (slimmer) {
    Object.assign(parsedStyle, {
      paddingTop: 0,
      paddingBottom: 0,
    });
  }

  return (
    <Menu.Item {...rest} style={parsedStyle}>
      {children}
    </Menu.Item>
  );
};

export default Element;
