import React from 'react';
import { translationManager } from '../../../lib/TranslationManager';
import LanguageSettingsPageUI from '../../ui/pages/LanguageSettingsPageUI';

const LanguageSettingsPage = (props) => {
  const {
    appState: {
      user: { lang = null },
    },
    setUserState = () => {},
    history,
  } = props;

  const onLangChange = (newLang) => {
    if (newLang !== lang) {
      setUserState({ lang: translationManager.setLanguage(newLang) });
    }
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <LanguageSettingsPageUI
      lang={lang || 'en'}
      supportedLanguages={{ en: 'English', es: 'Español', fr: 'Français' }}
      onLangChange={onLangChange}
      onBack={onBack}
    />
  );
};
export default LanguageSettingsPage;
