import React from 'react';
import BookingConfirmedPageUI from '../../ui/pages/BookingConfirmedPageUI';

const BookingConfirmed = (props) => {
  const {
    appState: {
      user: { loggedIn, ordersById },
      session: { orderId },
    },
  } = props;

  const lastOrder = ordersById[orderId];
  console.log('Last order: ', lastOrder);

  return (
    <BookingConfirmedPageUI
      createAccountLink="/create-account/"
      viewTicketsLink={`/orders/${orderId}`}
      {...{ /* outboundTrip, returnTrip, */ loggedIn }}
    />
  );
};
export default BookingConfirmed;
