import React from 'react';
import { findOneTicket } from '../lib/ordersUtils';
import TicketNotFoundPageUI from '../../../ui/pages/TicketNotFoundPageUI';

const withTicketFromUrl = WrappedComponent => (props) => {
  const {
    appState: {
      user: { ordersById = {} },
    },
    match: {
      params: { ticketId },
    },
    history,
  } = props;

  const ticket = findOneTicket(ordersById, { ticketId });
  console.log('ticket = ', ticket);
  if (!ticket) {
    return (
      <TicketNotFoundPageUI
        onBack={() => {
          history.goBack();
        }}
      />
    );
  }

  return (
    <WrappedComponent
      ticket={ticket}
      onBack={() => {
        history.goBack();
      }}
      {...props}
    />
  );
};

export default withTicketFromUrl;
