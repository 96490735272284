import React from 'react';
import { Image } from 'semantic-ui-react';
import SwipeableViews from 'react-swipeable-views';
import { Page, Bottom, Container } from '../theme';
import { withDefaultFont, withLinkButtonFont, withDiscreetLinkButtonFont } from '../theme/Fonts';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('BetaOnboardingCarousselPageUI');

const Step = ({
  picture, title = '', text = '', footer = '',
}) => (
  <div
    style={{
      flex: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '80vh',
    }}
  >
    <Image src={picture} style={{ height: '30vh' }} verticalAlign="middle" />
    <div
      style={withDefaultFont({
        marginTop: 30,
        textAlign: 'center',
        maxWidth: 'min(350px, 80vw)',
      })}
    >
      <div style={{ fontWeight: 'bold', marginBottom: 20 }}>{t(title.trim())}</div>
      {t(text.trim())}
      <div style={{ fontWeight: 'bold', marginTop: 20 }}>{t(footer.trim())}</div>
    </div>
  </div>
);

const BetaOnboardingCarousselPageUI = ({
  selectedStep = 0,
  steps = [],
  prevButtonDisabled = false,
  nextButtonLabel = 'Next',
  onPrev = () => {},
  onNext = () => {},
}) => {
  let reactSwipeEl;

  return (
    <Page>
      <div
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <SwipeableViews
          index={selectedStep}
          enableMouseEvents
          onChangeIndex={(index, indexLatest) => {
            if (indexLatest < index) {
              onNext();
            } else {
              onPrev();
            }
          }}
        >
          {steps.map(st => (
            <Step key={st.text} {...st} />
          ))}
        </SwipeableViews>
      </div>

      <Bottom>
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '5%',
          }}
        >
          <div
            style={withDiscreetLinkButtonFont({
              textDecoration: 'none',
              cursor: 'pointer',
              visibility: prevButtonDisabled ? 'hidden' : 'visible',
            })}
            onClick={() => {
              if (prevButtonDisabled) {
                return;
              }

              // reactSwipeEl.prev();
              onPrev();
            }}
          >
            {t('Previous')}
          </div>
          <div
            style={withLinkButtonFont({
              textDecoration: 'none',
              cursor: 'pointer',
            })}
            onClick={() => {
              // reactSwipeEl.next();
              onNext();
            }}
          >
            {t(nextButtonLabel)}
          </div>
        </Container>
      </Bottom>
    </Page>
  );
};

export default BetaOnboardingCarousselPageUI;
