import React from 'react';
import check from 'check-types';
import { Label } from 'semantic-ui-react';
import {
  ElementGroup,
  DefaultScreen,
  Icon,
  Bottom,
  Button,
  Container,
  PlaceHolderElement,
  KeyValueElement,
} from '../theme';

import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('EditPassengersPageUI');

const EditPassengersPageUI = ({
  userPassengerId = null,
  savedPassengers = [],
  onPassengerDelete = () => {},
  addPassengerLink = '',
  onBack = () => {},
}) => {
  const saved = savedPassengers || [];
  const addPassengerColor = '#00b9f1';

  saved.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

  return (
    <DefaultScreen title={t('Edit known passengers')} onBack={onBack}>
      <ElementGroup
        title={t('List of known passengers')}
        subtitle={t('Book tickets faster by saving you and your friends details here')}
      >
        {!check.nonEmptyArray(saved) && (
          <PlaceHolderElement>{t('No passenger saved yet')}</PlaceHolderElement>
        )}
        {saved.map((p) => {
          let title = `${p.firstName} ${p.lastName}`;
          if (userPassengerId === p.id) {
            title = (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <span>{title}</span>
                <Label color="purple" horizontal style={{ marginLeft: 15 }}>
                  {t('You')}
                </Label>
              </div>
            );
          }

          return (
            <KeyValueElement
              title={title}
              value={(
                <Icon
                  name="close"
                  style={{
                    color: 'gray',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    onPassengerDelete(p);
                  }}
                />
)}
            />
          );
        })}
      </ElementGroup>

      <Bottom>
        <Container>
          <Button
            to={addPassengerLink}
            fluid
            style={{ marginBottom: 15 }}
            color={addPassengerColor}
          >
            {t('Add passenger')}
          </Button>
        </Container>

        <Container>
          <Button onClick={onBack} fluid>
            {t('Back')}
          </Button>
        </Container>
      </Bottom>
    </DefaultScreen>
  );
};

export default EditPassengersPageUI;
