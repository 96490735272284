import React from 'react';
import { Redirect } from 'react-router-dom';
import withTicketFromUrl from './shared/withTicketFromUrl';
import TicketBoardingPassPageUI from '../../ui/pages/TicketBoardingPassPageUI';

// TODO: Implement on server
const transporterIdToTransporterName = (id) => {
  if (id === 1) {
    return 'cooploja';
  }
  if (id === 5) {
    return 'coopnambija';
  }
  if (id === 7) {
    return 'ruorenses';
  }
  if (id === 8) {
    return 'cooptrasandina';
  }
  if (id === 6) {
    return 'coopbanos';
  }
  if (id === 4) {
    return 'ucariamanga';
  }
  if (id === 3) {
    return 'uyantzaza';
  }

  return 'unknown';
};

// TODO: Implement on server
const ticketToQrCodeText = (ticket) => {
  const o = {
    tipoqr: 'boleto',
    empresa: transporterIdToTransporterName(ticket.ticketDetails.transporter.id),
    trn_codigo: ticket.apiData.trn_codigo,
  };

  return JSON.stringify(o);
};

const TicketBoardingPassPage = ({
  ticket,
  history,
  match: {
    params: { passengerIndex },
  },
}) => {
  if (passengerIndex > ticket.ticketDetails.passengers.length) {
    return <Redirect to={`/tickets/${ticket.id}/boarding-pass/0/`} />;
  }

  return (
    <TicketBoardingPassPageUI
      {...{
        ticket,
        onBack: () => {
          history.push(`/tickets/${ticket.id}/`);
        },
        qrCodeData: ticketToQrCodeText(ticket),
        passengerIndex: parseInt(passengerIndex || '0', 10),
      }}
    />
  );
};
export default withTicketFromUrl(TicketBoardingPassPage);
