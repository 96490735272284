import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './lang/en.json';
// import es from './lang/es.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en,
  //   es,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
