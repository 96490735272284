import React, { Component } from 'react';
import firebase from 'firebase';
import check from 'check-types';
import ForgotPasswordPageUI from '../../ui/pages/ForgotPasswordPageUI';
import ForgotPasswordLinkSentPageUI from '../../ui/pages/ForgotPasswordLinkSentPageUI';

export default class WelcomePage extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      ok: false,
      error: null,
      email: this.getCurrentAppEmail(),
    };

    // Bindings
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
  }

  async onResetPassword(email) {
    const { setUserState = () => {} } = this.props;

    // Reset password in Firebase
    // FIXME: Change return URL if embedded on mobile
    try {
      await firebase.auth().sendPasswordResetEmail(email, {
        url: window.location.href,
      });
      this.setState({ ok: true });
    } catch (error) {
      this.setState({ error });
    }

    // Update the user's email
    await setUserState({ email });
  }

  onEmailChange(email) {
    this.setState({ email });
  }

  getCurrentAppEmail() {
    const { appState } = this.props;
    let email;
    if (appState.user && check.nonEmptyString(appState.user.email)) {
      ({ email } = appState.user);
    }

    return email;
  }

  render() {
    const { ok, error = null, email } = this.state;
    const { history } = this.props;

    if (ok) {
      return <ForgotPasswordLinkSentPageUI onBack={() => history.push('/login/')} email={email} />;
    }

    return (
      <ForgotPasswordPageUI
        error={error}
        onBack={() => history.push('/login/')}
        email={email}
        onEmailChange={this.onEmailChange}
        onResetPassword={this.onResetPassword}
      />
    );
  }
}
