import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

import { withWidgetTitleFont } from './Fonts';

const PlaceHolder = ({ title, icon }) => (
  <div
    style={{
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Header icon style={withWidgetTitleFont({})}>
      <Icon name={icon} />
      {title}
    </Header>
  </div>
);

export default PlaceHolder;
