import React from 'react';
import { textColor } from './Colors';
import { withDefaultFont } from './Fonts';
import Title from './Title';
import Subtitle from './Subtitle';

const TitleBlock = ({
  title, subtitle, children, slim = false,
}) => (
  <div
    style={withDefaultFont({
      marginTop: slim ? 10 : 15,
      color: textColor,
    })}
  >
    {title && <Title>{title}</Title>}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    {children}
  </div>
);

export default TitleBlock;
