import React from 'react';
import check from 'check-types';
import { Link } from 'react-router-dom';

import {
  Page,
  HomeTopBar,
  Element,
  ElementGroup,
  Button,
  Container,
  TicketDetails,
  Bottom,
  Title,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('OrdersPageUI');

const OrdersPageUI = ({ tickets, isFiltered = false, onUnfilter = () => {} }) => (
  <Page>
    <HomeTopBar selectedTab="orders" />
    <Title style={{ marginTop: 20 }}>{t('Your next trips')}</Title>

    {tickets.map(ticket => (
      <Link to={`/tickets/${ticket.id}`}>
        <ElementGroup key={`ticket-thumb_${ticket.id}`}>
          <Element>
            <TicketDetails
              ticketDetails={ticket.ticketDetails}
              nPassengers={
                check.nonEmptyArray(ticket.ticketDetails.passengers)
                  ? ticket.ticketDetails.passengers.length
                  : 1
              }
            />
          </Element>
        </ElementGroup>
      </Link>
    ))}

    {isFiltered && (
      <Bottom>
        <Container>
          <Button fluid onClick={onUnfilter}>
            {t('Show all tickets')}
          </Button>
        </Container>
      </Bottom>
    )}
  </Page>
);

export default OrdersPageUI;
