import React from 'react';

const Spacer = () => (
  <div
    style={{
      height: 100,
    }}
  />
);

export default Spacer;
