import React from 'react';
import { Icon, discreetTextColor, withWidgetTitleFont } from '../../theme';

import { translatorForNamespace } from '../../../../lib/TranslationManager';

const t = translatorForNamespace('SelectedPassengers');

const SelectedPassengers = ({ passengers = [] }) => {
  passengers.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

  let title = t('No passenger selected');
  if (passengers.length > 0) {
    title = passengers.map(p => p.firstName).join(', ');
    if (title.includes(',')) {
      const n = title.lastIndexOf(',');
      title = `${title.substr(0, n)} ${t('and')}${title.substr(n + 1)}`;
    }
  }

  let style = withWidgetTitleFont({
    display: 'inline-flex',
    alignItems: 'center',
  });
  if (passengers.length === 0) {
    style = Object.assign(style, { color: discreetTextColor });
  }

  return (
    <div style={style}>
      <Icon name="user" style={{ marginRight: 15 }} />
      {' '}
      {title}
    </div>
  );
};

export default SelectedPassengers;
