import React from 'react';
import QRCode from 'qrcode.react';
import moment from 'moment';

import { Image } from 'semantic-ui-react';
import {
  Element,
  ElementGroup,
  PopupScreen,
  SquaredText,
  Bottom,
  Button,
  Icon,
  HighligthedText,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('TicketBoardingPassPageUI');

const TicketBoardingPassPageUI = ({
  ticket, qrCodeData, onBack = () => {}, passengerIndex,
}) => {
  // FIXME: Add passengers
  const passenger = ticket.ticketDetails.passengers[passengerIndex];
  const {
    firstName, lastName, seat, ssn,
  } = passenger;

  const KeyValue = ({ name, value }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ fontWeight: 'bold' }}>{name}</div>
      <div>{value}</div>
    </div>
  );

  console.log({
    firstName,
    lastName,
    seat,
    ssn,
  });

  const { ticketDetails } = ticket;
  const departure = moment.utc(ticketDetails.departureTime);
  const arrival = moment.utc(ticketDetails.arrivalTime);
  // const rideDuration = moment.duration(arrival.diff(departure));
  // const formattedDuration = `${rideDuration.hours()}h ${rideDuration.minutes()}m`;
  const nDaysAdded = moment
    .duration(
      moment.utc(arrival.format('YYYY-MM-DD')).diff(moment.utc(departure.format('YYYY-MM-DD'))),
    )
    .days();
  const d = nDaysAdded ? ` (+${nDaysAdded}d)` : '';
  const dh = departure.format('HH:mm');
  const ah = arrival.format('HH:mm');

  return (
    <PopupScreen title={t('Boarding Pass')} onBack={onBack}>
      <ElementGroup title={null /* `Here your ticket for ${passenger.firstName}` */}>
        <Element style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Image
            src={ticket.ticketDetails.transporter.logo}
            width={50}
            style={{ marginRight: 20 }}
          />
          <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
            {ticket.ticketDetails.transporter.name}
          </div>
        </Element>
        <Element style={{ textAlign: 'center' }}>{departure.format('LL')}</Element>
        <Element style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '40%' }}>
            <div style={{ marginBottom: 10, fontWeight: 'bold' }}>
              {ticket.ticketDetails.originTerminal.name}
            </div>
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{t('Departing')}</span>
              <br />
              <span style={{ fontWeight: 'normal', fontSize: '10px' }}>{dh}</span>
            </div>
          </div>
          <div style={{ width: '20%', textAlign: 'center' }}>
            <Icon
              name="arrow alternate circle right outline"
              style={{ color: 'gray', fontSize: '3rem' }}
            />
          </div>
          <div style={{ width: '40%', textAlign: 'right' }}>
            <div style={{ marginBottom: 10, fontWeight: 'bold' }}>
              {ticket.ticketDetails.destinationTerminal.name}
            </div>
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{t('Arriving')}</span>
              <br />
              <span style={{ fontWeight: 'normal', fontSize: '10px' }}>{`${ah}${d}`}</span>
            </div>
          </div>
        </Element>
        <Element>
          <KeyValue name="First name" value={<HighligthedText>{firstName}</HighligthedText>} />
          <KeyValue name="Last name" value={lastName} />
          <KeyValue name="Cedula" value={ssn || '1234567890'} />
          <div style={{ height: 20 }} />
          <KeyValue
            name="Seat"
            value={<SquaredText style={{ width: 24, height: 24 }}>{seat}</SquaredText>}
          />
        </Element>

        <Element
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <QRCode value={qrCodeData} size={90} />
          <pre>{ticket.id.toUpperCase()}</pre>
        </Element>
      </ElementGroup>

      {ticket.ticketDetails.passengers.length > 1 && (
        <Bottom>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <Button
              disabled={passengerIndex === 0}
              to={`/tickets/${ticket.id}/boarding-pass/${Math.max(0, passengerIndex - 1)}/`}
            >
              {'←'}
            </Button>
            <Button
              disabled={passengerIndex === ticket.ticketDetails.passengers.length - 1}
              to={`/tickets/${ticket.id}/boarding-pass/${passengerIndex + 1}/`}
            >
              {'→'}
            </Button>
          </div>
        </Bottom>
      )}
    </PopupScreen>
  );
};

export default TicketBoardingPassPageUI;
