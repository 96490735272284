import React from 'react';
import { discreetTextColor } from './Colors';
import { withWidgetSubtitleFont } from './Fonts';

const Subtitle = ({ style = {}, children }) => (
  <div
    style={Object.assign(
      withWidgetSubtitleFont({
        paddingLeft: '5%',
        paddingRight: '5%',
        color: discreetTextColor,
        marginTop: 5,
        marginBottom: 5,
      }),
      style,
    )}
  >
    {children}
  </div>
);

export default Subtitle;
