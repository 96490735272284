import React from 'react';
import uniqid from 'uniqid';
import check from 'check-types';
import AddPassengerPopupUI from '../../ui/pages/AddPassengerPopupUI';

const AddPassengerPopup = (props) => {
  const {
    appState: {
      user: { savedPassengers = {} },
      session: { passengerDetails = [] },
    },
    history,
    setSession = () => {},
    setUserState = () => {},
  } = props;

  // TODO: Put function to add a passenger in a shared lib?
  const onAdd = async (p) => {
    if (!check.nonEmptyString(p.firstName) || !check.nonEmptyString(p.lastName)) {
      return;
    }

    // Create new passenger
    const newPassenger = Object.assign({ id: uniqid() }, p);

    // Save it and select it
    await setUserState({
      savedPassengers: Object.assign({}, savedPassengers, { [newPassenger.id]: newPassenger }),
    });

    const newPassengerDetails = [...(passengerDetails || []), newPassenger];
    await setSession({
      passengerDetails: newPassengerDetails,
      // numberOfPassengers: newPassengerDetails.length,
    });

    // Go back
    history.goBack();
  };

  return <AddPassengerPopupUI onAdd={onAdd} onBack={history.goBack} />;
};
export default AddPassengerPopup;
