import React from 'react';
import { Redirect } from 'react-router-dom';
import GenericTripSeatSelectionPage from './shared/GenericTripSeatSelectionPage';

const ReturnTripSeatSelectionPage = ({
  appState: {
    session: {
      selectedDeparture, selectedArrival, returnDate, returnTrip, numberOfPassengers,
    },
    cache: { rides = [] },
  },
  history,
  setSession,
}) => {
  if (!returnDate) {
    return <Redirect to="/book/return/select/" />;
  }

  return (
    <GenericTripSeatSelectionPage
      origin={selectedArrival}
      destination={selectedDeparture}
      date={returnDate}
      selectedTrip={returnTrip}
      isReturn={false}
      numberOfSeatsToSelect={numberOfPassengers}
      ridesCache={rides}
      history={history}
      setTrip={async (trip) => {
        await setSession({ returnTrip: trip });
      }}
    />
  );
};

export default ReturnTripSeatSelectionPage;
