import React, { Component } from 'react';
import firebase from 'firebase';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import check from 'check-types';
// import SignInOrSignUpPage from '../pages/shared/SignInOrSignUpPage';
import isNative from '../../../lib/is-native';
import BetaOnboardingCarousselPage from '../../ui/pages/BetaOnboardingCarousselPageUI';
import BetaOnboardingWaitingPage from '../../ui/pages/BetaOnboardingWaitingPageUI';
import step1Pic from './assets/step1.svg';
import step2Pic from './assets/step2.svg';
import step3Pic from './assets/step3.svg';
import step4Pic from './assets/step4.svg';

const ONBOARDING_STEPS = [
  {
    picture: step1Pic,
    title: 'Bienvenidos a Busea!',
    text: 'La aplicación que revolucionara la manera que compras tus boletos de bus en el Ecuador.',
  },
  {
    picture: step2Pic,
    title: 'Compra tus boletos de Bus desde tu teléfono!',
    text:
      'Olvidate de desplazarte al Terminal o hacer fila. Viaja por el Ecuador sin perder tiempo.',
  },
  {
    picture: step3Pic,
    title: 'Toda la informacion en un solo lugar.',
    text:
      'Compara rutas, horarios, frecuencias, precios y Cooperativas de Transporte para llegar a tu destino deseado.',
  },
  {
    picture: step4Pic,
    title: 'Pago en linea 100% seguro',
    text:
      'Paga tu boleto de bus en nuestra App con tu tarjeta de débito o crédito de cualquier banco. Accede a tus pasajes desde tu teléfono.',
    footer: 'Comienza a probarla desde ahora!',
  },
];

export default class BetaOnboarding extends Component {
  constructor(...args) {
    super(...args);

    let step = 0;

    if (window && window.location && check.nonEmptyString(window.location.search)) {
      const parsedQuery = queryString.parse(window.location.search);
      if (parsedQuery.step) {
        step = parseInt(parsedQuery.step, 10) || 0;
      }
    }

    this.state = {
      step,
    };
  }

  async onLogOut() {
    const { setUserState = () => {} } = this.props;

    if (isNative()) {
      // To remove? Already in Busea.js
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'log-out' }));
    }
    await firebase.auth().signOut();
    await setUserState({ loggedIn: false });
  }

  render() {
    const {
      appState: {
        user: { loggedIn = false },
      },
    } = this.props;
    const { step } = this.state;

    if (loggedIn) {
      return <BetaOnboardingWaitingPage onLogout={() => this.onLogOut()} />;
    }

    console.log(`step = ${step}`);
    console.log(`ONBOARDING_STEPS.length = ${ONBOARDING_STEPS.length}`);

    if (step < ONBOARDING_STEPS.length) {
      return (
        <BetaOnboardingCarousselPage
          selectedStep={step}
          steps={ONBOARDING_STEPS}
          prevButtonDisabled={step === 0}
          nextButtonLabel={step === ONBOARDING_STEPS.length - 1 ? 'Sign up' : 'Next'}
          onPrev={() => this.setState({ step: Math.max(step - 1, 0) })}
          onNext={() => this.setState({ step: Math.min(step + 1, ONBOARDING_STEPS.length) })}
        />
      );
    }

    return (
      /*
      <SignInOrSignUpPage
        type="signUp"
        {...this.props}
        onBack={() => this.setState({ step: ONBOARDING_STEPS.length - 1 })}
      />
      */
      <Redirect
        to={`/create-account/?back=${encodeURIComponent(`/?step=${ONBOARDING_STEPS.length - 1}`)}`}
      />
    );
  }
}
