import React from 'react';
import moment from 'moment';
import DateSelectionPopupUI from '../../ui/pages/DateSelectionPopupUI';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('ReturnDatePopup');

const ReturnDatePopup = (props) => {
  const {
    appState: {
      session: { outDate = null, returnDate = null },
    },
    history,
    setSession = () => {},
  } = props;

  const onDateSelected = async (d) => {
    await setSession({ returnDate: d.format('YYYY-MM-DD') });
    history.goBack();
  };

  return (
    <DateSelectionPopupUI
      title={t('Return date')}
      date={returnDate ? moment(returnDate, 'YYYY-MM-DD') : null}
      minDate={outDate ? moment(outDate, 'YYYY-MM-DD') : moment().add(3, 'hours')}
      maxDate={moment().add(1, 'months')}
      onChange={onDateSelected}
      onBack={history.goBack}
    />
  );
};
export default ReturnDatePopup;
