import React from 'react';
import { Form, Message, Icon } from 'semantic-ui-react';

import {
  DefaultScreen,
  Element,
  ElementGroup,
  Divider,
  Container,
  Bottom,
  LinkButton,
  Button,
} from '../../theme';
import { withWidgetSubtitleFont } from '../../theme/Fonts';

import { translatorForNamespace } from '../../../../lib/TranslationManager';

const t = translatorForNamespace('SignInOrSignUpPageUI');

const SignInOrSignUpPageUI = ({
  error,
  email,
  password,
  onEmailChange = () => {},
  onPasswordChange = () => {},
  onSubmit = () => {},
  onFacebookSignIn = () => {},
  onGoogleSignIn = () => {},
  title,
  dividerText,
  onBack = () => {},
}) => (
  <DefaultScreen title={title} onBack={onBack}>
    <ElementGroup>
      <Element>
        <Form onSubmit={onSubmit} error={!!error}>
          {!!error && <Message error header={t('Error')} content={error.message} />}

          <Form.Field>
            <Form.Input
              placeholder={t('E-mail')}
              value={email || ''}
              onChange={(e, o) => onEmailChange(o.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              type="password"
              placeholder={t('Password')}
              value={password || ''}
              onChange={(e, o) => onPasswordChange(o.value)}
            />
          </Form.Field>
          <Button fluid type="submit" primary>
            {title}
          </Button>
        </Form>
      </Element>
    </ElementGroup>
    <Container
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 10,
      }}
    >
      <LinkButton
        to="/login/forgot-password/"
        style={withWidgetSubtitleFont({ fontSize: '0.9rem' })}
      >
        {t('Forgot your password?')}
      </LinkButton>
    </Container>

    <Divider text={dividerText} />

    <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Button color="facebook" fluid onClick={onFacebookSignIn} style={{ marginRight: '2.5%' }}>
        <Icon name="facebook" />
        {' Facebook'}
      </Button>
      <Button color="google plus" fluid onClick={onGoogleSignIn} style={{ marginLeft: '2.5%' }}>
        <Icon name="google" />
        {' Google'}
      </Button>
    </Container>

    <Bottom>
      <Container style={{ textAlign: 'center' }}>
        <LinkButton to="/create-account/">{t('Create a free account')}</LinkButton>
      </Container>
    </Bottom>
  </DefaultScreen>
);

export default SignInOrSignUpPageUI;
