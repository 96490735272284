import React from 'react';

export default function SquaredText({ style = {}, children }) {
  return (
    <div
      style={Object.assign(
        {
          border: 'solid 1px #ced6dc',
          borderRadius: 2,
          padding: 3,
          margin: 2,
          display: 'inline-flex',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        },
        style,
      )}
    >
      {children}
    </div>
  );
}
