import React from 'react';

import {
  PopupScreen, Bottom, Container, Button, PlaceHolder,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('SignUpOkPageUI');

const SignUpOkPageUI = ({ nextLabel, nextLink }) => (
  <PopupScreen title={t('Sign up')}>
    <PlaceHolder text={t('Good to go!')} icon="check" />
    <Bottom>
      <Container>
        <Button to={nextLink} primary fluid>
          {nextLabel}
        </Button>
      </Container>
    </Bottom>
  </PopupScreen>
);

export default SignUpOkPageUI;
