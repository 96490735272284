import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import {
  Element, ElementGroup, DefaultScreen, Icon, Bottom, Button, Container,
} from '../theme';
import SelectedPassengers from './shared/SelectedPassengers';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('PassengersSelectionUI');

const PassengersSelectionUI = ({
  numberOfPassengersToSelect = 0,
  savedPassengers = [],
  selectedPassengers = [],
  onPassengerToggle = () => {},
  addPassengerLink = '',
  confirmLink = '',
  onBack = () => {},
}) => {
  const selected = selectedPassengers || [];
  const saved = savedPassengers || [];

  saved.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

  const addPassengerColor = '#00b9f1';
  /*
  if (saved.length < numberOfPassengersToSelect) {
    addPassengerColor = '#7200da';
  }
  */

  const allPassengersSelected = selected.length === numberOfPassengersToSelect;

  let subtitle = (
    <span>
      {t('We need to know the names of the passengers on this trip')}
      <br />
      {t('Please insert their details below')}
    </span>
  );

  if (saved.length > 0) {
    subtitle = (
      <span>
        {t('Use this screen to indicate who will be on this trip.')}
        <br />
        {t('{XofY} passengers confirmed', {
          XofY: `${selected.length}/${numberOfPassengersToSelect}`,
        })}
      </span>
    );
  }

  const topBarRightChildren = (
    <div style={{ display: 'inline-flex', marginRight: 10 }}>
      <span>{`${selected.length}/${numberOfPassengersToSelect}`}</span>
      <Icon style={{ marginLeft: 10 }} name="user" />
    </div>
  );

  return (
    <DefaultScreen
      title={t('Select passengers')}
      onBack={onBack}
      topBarRightChildren={topBarRightChildren}
    >
      <ElementGroup title={t('Passengers in this trip')} subtitle={subtitle}>
        <Element>
          <SelectedPassengers passengers={selected} />
        </Element>
      </ElementGroup>

      {saved.length > 0 && (
        <ElementGroup>
          {saved.map((p) => {
            const checked = selected.some(sp => sp.id === p.id);
            const disabled = !checked && allPassengersSelected;
            const onClick = disabled
              ? () => {}
              : () => {
                onPassengerToggle(p);
              };

            return (
              <Element key={p.id} onClick={onClick}>
                <Checkbox
                  checked={checked}
                  label={`${p.firstName} ${p.lastName}`}
                  style={{ marginRight: 15 }}
                  disabled={disabled}
                />
              </Element>
            );
          })}
        </ElementGroup>
      )}

      <Bottom>
        {selected.length < numberOfPassengersToSelect && (
          <Container>
            <Button
              to={addPassengerLink}
              fluid
              style={{ marginBottom: 15 }}
              color={addPassengerColor}
            >
              {t('Add passenger')}
            </Button>
          </Container>
        )}

        <Container>
          <Button to={confirmLink} fluid primary disabled={!allPassengersSelected}>
            {t('Confirm')}
          </Button>
        </Container>
      </Bottom>
    </DefaultScreen>
  );
};

export default PassengersSelectionUI;
