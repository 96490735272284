import React from 'react';
import Element from './Element';
import Title from './Title';
import Subtitle from './Subtitle';

const KeyValueElement = ({
  title, subtitle, value, children, slim = false, style, ...rest
}) => (
  <Element {...rest} style={style} slim={slim}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: slim ? 0 : 5,
        marginBottom: children ? 15 : slim ? 0 : 5,
      }}
    >
      {(title || subtitle) && (
        <div style={{ flex: '1' }}>
          {title && <Title style={{ paddingLeft: 2, fontSize: '1rem' }}>{title}</Title>}
          {subtitle && <Subtitle style={{ paddingLeft: 2 }}>{subtitle}</Subtitle>}
        </div>
      )}

      {value && <div style={{ fontSize: '1rem' }}>{value}</div>}
    </div>

    {children}
  </Element>
);

export default KeyValueElement;
