import React from 'react';
import { Link } from 'react-router-dom';
import PaymentIcon from 'react-payment-icons';

import {
  DefaultScreen,
  ElementGroup,
  Bottom,
  Container,
  Button,
  KeyValueElement,
  PlaceHolderElement,
  Icon,
  withPlaceholderFont,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('SettingsPageUI');

const SettingsPageUI = ({
  loggedIn,
  selectedLanguage,
  version,
  card,
  savedPassengers = [],
  onEditPassengers = () => {},
  onConnect = () => {},
  // onLangChange = () => {},
  onLogOut = () => {},
  onBack = () => {},
  onDeleteCard = () => {},
}) => {
  let passengerSectionTitle = t('No passenger saved');
  if (savedPassengers.length === 1) {
    passengerSectionTitle = t('1 passenger saved');
  } else if (savedPassengers.length > 1) {
    passengerSectionTitle = t('{x} passengers saved', { x: savedPassengers.length });
  }

  return (
    <DefaultScreen title={t('Settings')} onBack={onBack}>
      {/*
      <Title style={{ marginTop: 20 }}>
      Hello
      {' <firstName>'}! </Title>
      */}

      <ElementGroup title={t('Your settings')}>
        {/* <Element onClick={() => onLangChange(lang)}>
        Language:
        {lang}
      </Element> */}
        <Link to="/settings/language">
          <KeyValueElement
            title={t('Language:')}
            value={(
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <span>{selectedLanguage}</span>
                <Icon
                  name="angle right"
                  style={{
                    color: 'gray',
                    cursor: 'pointer',
                    marginLeft: 15,
                    marginBottom: 1,
                  }}
                />
              </div>
)}
          />
        </Link>
      </ElementGroup>
      <ElementGroup title={t('Your payment information')}>
        {!card && <PlaceHolderElement>{t('No credit or debit card saved')}</PlaceHolderElement>}
        {card && (
          <KeyValueElement
            title={(
              <div
                style={withPlaceholderFont({
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                })}
              >
                <PaymentIcon
                  id={card.brand.toLowerCase()}
                  style={{ display: 'inline', height: '1.2rem', marginRight: 10 }}
                />
                {`•••• •••• •••• ${card.last4}`}
              </div>
)}
            value={(
              <Icon
                name="close"
                style={{
                  color: 'gray',
                  cursor: 'pointer',
                }}
                onClick={onDeleteCard}
              />
)}
          />
        )}
      </ElementGroup>
      <ElementGroup
        title={t('Passenger information')}
        subtitles={t('Super useful to avoid entering data twice')}
      >
        {/* (
        <PlaceHolderElement>No passenger saved yet</PlaceHolderElement>
      ) */}

        <KeyValueElement
          onClick={onEditPassengers}
          title={passengerSectionTitle}
          value={(
            <Icon
              name="angle right"
              style={{
                color: 'gray',
                cursor: 'pointer',
              }}
            />
)}
        />
      </ElementGroup>
      <ElementGroup title={t('App information')}>
        <KeyValueElement
          title={t('Version:')}
          value={<span style={{ color: 'gray' }}>{version}</span>}
        />
        {/*
      <Element>
        Version:
        {version}
      </Element>
      */}
      </ElementGroup>
      <Bottom>
        <Container>
          <Button
            fluid
            primary={!loggedIn}
            color={loggedIn ? 'google plus' : null}
            onClick={loggedIn ? onLogOut : onConnect}
          >
            {loggedIn ? t('Log out') : t('Log in')}
          </Button>
        </Container>
      </Bottom>
    </DefaultScreen>
  );
};

export default SettingsPageUI;
