import React from 'react';
import { Link } from 'react-router-dom';
import { Button as NativeButton } from 'semantic-ui-react';

import { primaryColor, defaultButtonColor, onDarkTextColor } from './Colors';
import { withDefaultFont } from './Fonts';

const Button = ({
  primary, style, to, color, onClick, children, ...rest
}) => {
  const defaultStyle = withDefaultFont({ color: onDarkTextColor, borderRadius: 0 });
  if (primary) {
    defaultStyle.backgroundColor = primaryColor;
  } else if (!color) {
    defaultStyle.backgroundColor = defaultButtonColor;
  } else if (color.indexOf('#') === 0) {
    defaultStyle.backgroundColor = color;
  }

  let as;
  if (to && !onClick) {
    as = Link;
  }

  const buttonContent = (
    <NativeButton
      as={as}
      to={to}
      color={color && color.indexOf('#') === -1 ? color : null}
      style={Object.assign(defaultStyle, style)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </NativeButton>
  );
  /*
  if (to && !onClick) {

    buttonContent = <Link to={to}>{buttonContent}</Link>;
  } */

  return buttonContent;
};

export default Button;
