import React from 'react';
import PassengersSelectionUI from '../../ui/pages/PassengersSelectionUI';

const PassengersDetailsPage = (props) => {
  const {
    appState: {
      user: { savedPassengers = {} },
      session: { passengerDetails = [], numberOfPassengers },
    },
    history,
    setSession = () => {},
  } = props;

  const pDetails = passengerDetails || [];
  const savedP = Object.values(savedPassengers);

  const onPassengerToggle = async (p) => {
    // If passenger is in selection, remove her
    let newPassengerDetails = [];
    if (pDetails.some(pg => pg.id === p.id)) {
      newPassengerDetails = pDetails.filter(pg => pg.id !== p.id);
    } else {
      newPassengerDetails = [...pDetails, p];
    }

    await setSession({
      passengerDetails: newPassengerDetails,
      // numberOfPassengers: newPassengerDetails.length,
    });
  };

  return (
    <PassengersSelectionUI
      numberOfPassengersToSelect={numberOfPassengers}
      savedPassengers={savedP}
      selectedPassengers={pDetails}
      onPassengerToggle={onPassengerToggle}
      addPassengerLink="/settings/passengers/add"
      confirmLink="/book/checkout/"
      onBack={history.goBack}
    />
  );
};
export default PassengersDetailsPage;
