import React from 'react';
import moment from 'moment';
import { Form, Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import removeAccents from 'remove-accents';
import NumberPicker from '../../vendors/semantic-ui-react-numberpicker';

import {
  Page, Button, Container, HomeTopBar, Icon,
} from '../theme';

import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('BookingFormPageUI');

const dropDownSearch = (items, query) => {
  const q = removeAccents(query.trim().toUpperCase());

  const results = items.filter(i => i.value.includes(q));
  results.sort((a, b) => {
    if (
      a.value.indexOf(q) !== b.value.indexOf(q)
      && (a.value.indexOf(q) === 0 || b.value.indexOf(q) === 0)
    ) {
      return a.value.indexOf(q) - b.value.indexOf(q);
    }
    return a.value.localeCompare(b.value);
  });

  return results;
};

const ClosableInput = ({
  value, placeholder, valueLink = '', onClear = () => {},
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 38,
      backgroundColor: 'white',
      borderRadius: '5px',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 14,
      color: 'rgba(0,0,0,.87)',
    }}
  >
    <Link
      to={valueLink}
      style={{
        display: 'flex',
        alignItems: 'center',
        flex: '1',
        height: '100%',
        paddingLeft: 15,
        color: value ? 'rgba(0,0,0,.87)' : 'gray',
      }}
    >
      {value || placeholder}
    </Link>
    <div
      style={{
        height: '100%',
        paddingLeft: 20,
        paddingRight: 7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontSize: '0.85em',
        cursor: 'pointer',
      }}
      onClick={value ? onClear : () => {}}
    >
      {value && <Icon name="close" color="gray" />}
    </div>
  </div>
);

const BookingFormPageUI = ({
  onChange = () => {},
  onSubmit = () => {},
  selectedDeparture = null,
  selectedArrival = null,
  numberOfPassengers = 1,
  outDate = moment().format('YYYY-MM-DD'),
  returnDate = null,
  departures,
  arrivals,
}) => {
  const formFields = {
    selectedDeparture,
    selectedArrival,
    numberOfPassengers,
    outDate,
    returnDate,
  };

  return (
    <Page>
      <HomeTopBar>
        <Container>
          <Form
            inverted
            style={{
              backgroundColor: 'none',
              width: '100%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          >
            <Form.Field>
              <label>{t('From')}</label>
              <Dropdown
                value={selectedDeparture}
                placeholder={t('From')}
                fluid
                clearable
                closeOnBlur
                search={dropDownSearch}
                closeOnEscape
                selection
                options={departures}
                onChange={(e, o) => onChange(Object.assign(formFields, { selectedDeparture: o.value }))
                }
              />
            </Form.Field>

            <Form.Field>
              <label>{t('To')}</label>
              <Dropdown
                value={selectedArrival}
                placeholder={t('To')}
                fluid
                clearable
                closeOnBlur
                closeOnEscape
                selection
                options={arrivals}
                onChange={(e, o) => onChange(Object.assign(formFields, { selectedArrival: o.value }))
                }
                search={dropDownSearch}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Out date')}</label>

              <ClosableInput
                value={outDate ? moment(outDate, 'YYYY-MM-DD').format('ll') : null}
                placeholder={t('Departure date')}
                valueLink="/book/details/departure-date"
                onClear={() => {
                  onChange(Object.assign(formFields, { outDate: null, returnDate: null }));
                }}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Return date')}</label>

              <ClosableInput
                value={returnDate ? moment(returnDate, 'YYYY-MM-DD').format('ll') : null}
                placeholder={t('No return')}
                valueLink="/book/details/return-date"
                onClear={() => {
                  onChange(Object.assign(formFields, { returnDate: null }));
                }}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Number of passengers')}</label>
              <NumberPicker
                min={1}
                name="numberPicker"
                value={numberOfPassengers}
                onChange={up => onChange(Object.assign(formFields, { numberOfPassengers: up.value }))
                }
              />
            </Form.Field>
            <Button
              type="submit"
              primary
              fluid
              onClick={() => onSubmit(formFields)}
              style={{ marginTop: 30 }}
            >
              {t('Search')}
            </Button>
          </Form>
        </Container>
      </HomeTopBar>
      <Container style={{ marginTop: '5%' }} />
    </Page>
  );
};

export default BookingFormPageUI;
