import React from 'react';
import { translatorForNamespace } from '../../../lib/TranslationManager';
import {
  PopupScreen,
  ElementGroup,
  Element,
  Icon,
  primaryColor,
  widgetBorderColor,
} from '../theme';

const t = translatorForNamespace('LanguageSettingsPageUI');

const LanguageSettingsPageUI = ({
  lang,
  supportedLanguages,
  onLangChange = () => {},
  onBack = () => {},
}) => (
  <PopupScreen title={t('Language settings')} onBack={onBack} leftIcon="close">
    <ElementGroup title={t('Select your preferred language')}>
      {Object.keys(supportedLanguages).map(langKey => (
        <Element
          onClick={() => {
            onLangChange(langKey);
            onBack();
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Icon
            name={lang === langKey ? 'check' : 'circle outline'}
            style={{
              visibility: 'visible', // lang === langKey ? 'visible' : 'hidden',
              color: lang === langKey ? primaryColor : widgetBorderColor,
              display: 'inline',
              marginRight: 15,
              marginBottom: 2,
            }}
          />
          <span>{supportedLanguages[langKey]}</span>
        </Element>
      ))}
    </ElementGroup>
  </PopupScreen>
);

export default LanguageSettingsPageUI;
