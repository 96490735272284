import React from 'react';
import GenericTripSeatSelectionPage from './shared/GenericTripSeatSelectionPage';

const OutTripSeatSelectionPage = ({
  appState: {
    session: {
      selectedDeparture, selectedArrival, outDate, outboundTrip, numberOfPassengers,
    },
    cache: { rides = [] },
  },
  history,
  setSession,
}) => (
  <GenericTripSeatSelectionPage
    origin={selectedDeparture}
    destination={selectedArrival}
    date={outDate}
    selectedTrip={outboundTrip}
    isReturn={false}
    numberOfSeatsToSelect={numberOfPassengers}
    ridesCache={rides}
    history={history}
    setTrip={async (trip) => {
      await setSession({ outboundTrip: trip });
    }}
  />
);

export default OutTripSeatSelectionPage;
