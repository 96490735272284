import React, { Fragment, Component } from 'react';
import check from 'check-types';
import firstMatch from 'first-match';
import { Redirect } from 'react-router-dom';
import ExpandedRideListener from '../../listeners/ExpandedRideListener';
import GenericTripSeatSelectionPageUI from '../../../ui/pages/GenericTripSeatSelectionPageUI';

export default class GenericTripSeatSelectionPage extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      error: null,
    };

    // Bindings
    this.onBack = this.onBack.bind(this);
    this.onSeatToggle = this.onSeatToggle.bind(this);
  }

  onBack() {
    const { history } = this.props;

    history.goBack();
  }

  async onSeatToggle(seat) {
    const { selectedTrip, numberOfSeatsToSelect, setTrip = () => {} } = this.props;

    const { selectedSeats = [] } = selectedTrip;
    let newSelectedSeats = [];

    if (selectedSeats.includes(seat)) {
      newSelectedSeats = selectedSeats.filter(s => s !== seat);
    } else if (selectedSeats.length < numberOfSeatsToSelect) {
      newSelectedSeats = [...selectedSeats, seat];
    } else {
      this.setState({ error: true });
      return;
    }

    // Update seats
    const newTrip = Object.assign({}, selectedTrip, {
      selectedSeats: newSelectedSeats,
    });
    await setTrip(newTrip);
    this.setState({ error: false });
  }

  getSeatMatrix() {
    const {
      origin, destination, date, selectedTrip = null, ridesCache,
    } = this.props;

    const path = `${origin}/${destination}/${date}`;
    const rides = ridesCache[path] || [];
    const ride = firstMatch(rides, r => r.id === selectedTrip.id);
    const seatMatrix = ride ? ride.seatMatrix || [] : null;

    return seatMatrix;
  }

  render() {
    const {
      origin,
      destination,
      date,
      selectedTrip = null,
      isReturn = false,
      numberOfSeatsToSelect = 0,
    } = this.props;
    const { error } = this.state;

    if (!origin || !destination || !date) {
      return <Redirect to="/book/" />;
    }
    if (!selectedTrip) {
      return <Redirect to={`/book/${isReturn ? 'return' : 'out'}/`} />;
    }

    const seatMatrix = this.getSeatMatrix();

    return (
      <Fragment>
        <GenericTripSeatSelectionPageUI
          {...{
            error,
            selectedTrip,
            seatMatrix,
            selectedSeats: selectedTrip.selectedSeats || [],
            loading: !check.nonEmptyArray(seatMatrix),
            isReturn,
            onSeatToggle: this.onSeatToggle,
            numberOfSeatsToSelect,
            onBack: this.onBack,
          }}
        />
      </Fragment>
    );
  }
}
