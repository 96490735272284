import React from 'react';
import TopBar from './TopBar';

const HomeTopBar = props => (
  <TopBar
    {...Object.assign(
      {
        selectedTab: 'book',
        tabs: [
          {
            link: '/book/',
            name: 'book',
            icon: 'search',
            displayName: 'New trip',
          },
          {
            link: '/orders/',
            name: 'orders',
            displayName: 'My tickets',
            icon: 'ticket',
          },
        ],
        rightIcon: 'user',
        rightIconLink: '/settings/',
      },
      props,
    )}
  />
);

export default HomeTopBar;
