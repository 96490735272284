import React from 'react';
import moment from 'moment';
import DateSelectionPopupUI from '../../ui/pages/DateSelectionPopupUI';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('DepartureDatePopup');

const DepartureDatePopup = (props) => {
  const {
    appState: {
      session: { outDate = null, returnDate = null },
    },
    history,
    setSession = () => {},
  } = props;

  const onDateSelected = async (d) => {
    if (d.valueOf() > moment(returnDate, 'YYYY-MM-DD').valueOf()) {
      await setSession({ returnDate: d.format('YYYY-MM-DD') });
    }
    await setSession({ outDate: d.format('YYYY-MM-DD') });
    history.goBack();
  };

  return (
    <DateSelectionPopupUI
      title={t('Departure date')}
      date={outDate ? moment(outDate, 'YYYY-MM-DD') : null}
      minDate={moment().add(3, 'hours')}
      maxDate={moment().add(1, 'months')}
      onChange={onDateSelected}
      onBack={history.goBack}
    />
  );
};
export default DepartureDatePopup;
