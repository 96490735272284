import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import request from 'request-promise';
import moment from 'moment';
import check from 'check-types';
import firebase from 'firebase';
import uniqid from 'uniqid';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CreditCardPaymentPageUI from '../../ui/pages/CreditCardPaymentPageUI';
import ProcessingPayementLoaderPageUI from '../../ui/pages/ProcessingPayementLoaderPageUI';
import stripeConfig from '../../../config/stripe';

export default class CreditCardPaymentPage extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      processing: false,
      error: null,
      success: false,
    };

    // Bindings
    this.onPaymentValidated = this.onPaymentValidated.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.setUserPassengerDetails = this.setUserPassengerDetails.bind(this);
    this.setUserPassengerId = this.setUserPassengerId.bind(this);
  }

  async componentDidMount() {
    const {
      newCard = false,
      appState: {
        user: { loggedIn, email, cards },
      },
    } = this.props;

    if (loggedIn && check.nonEmptyObject(cards) && !newCard) {
      await this.onPaymentValidated(email, null);
    }
  }

  async onPaymentValidated(email, cardToken) {
    const {
      appState: {
        session: { outboundTrip = null, returnTrip = null, passengerDetails },
        user: { loggedIn, ordersById = {} },
      },
      setSession = () => {},
      setUserState = () => {},
    } = this.props;

    // Activate loading page
    await this.setState({ processing: true, error: null, success: false });

    console.log('Card Token from Stripe: ', cardToken);

    // Process order
    // FIXME: Only outbound for now
    const body = {
      outboundRideId: outboundTrip.id,
      outboundSeats: outboundTrip.selectedSeats,
      returnRideId: returnTrip ? returnTrip.id : null,
      returnSeats: returnTrip ? returnTrip.selectedSeats : [],
      passengerDetails,
      email,
      cardToken,
      amount:
        passengerDetails.length
        * (Math.round(outboundTrip.price * 100)
          + (returnTrip ? Math.round(returnTrip.price * 100) : 0)),
    };

    const headers = {};
    if (loggedIn && firebase.auth().currentUser) {
      const authToken = await firebase.auth().currentUser.getIdToken(true);
      headers.Authorization = `Bearer ${authToken}`;
    }

    try {
      console.log('⚙️Submitting reservation...');
      const response = await request({
        method: 'post',
        uri: 'https://us-central1-busea-web.cloudfunctions.net/api/',
        json: true,
        headers,
        body,
      });

      console.log('✅Reservation OK: ', response);

      // Store tickets in the user object
      // Erase most session values
      const order = response;
      const newUserState = { ordersById: Object.assign({}, ordersById, { [order.id]: order }) };

      // Saving user card info in localStorage
      // FIXME: This is dirty. Save the card in an anonymous session on server instead?
      if (check.nonEmptyObject(cardToken) && check.nonEmptyObject(cardToken.card)) {
        Object.assign(newUserState, {
          cards: {
            [`${cardToken.card.brand}_${cardToken.card.last4}`]: {
              brand: cardToken.card.brand,
              last4: cardToken.card.last4,
            },
          },
        });
      }
      await setUserState(newUserState);
      await setSession({
        orderId: order.id,

        selectedDeparture: null,
        selectedArrival: null,
        numberOfPassengers: 1,
        outDate: moment().format('YYYY-MM-DD'),
        returnDate: null,
        passengerDetails: null,
        outboundTrip: null,
        returnTrip: null,
      });

      // Indicate success
      await this.setState({ processing: false, success: true });
    } catch (error) {
      console.log(error);
      await this.setState({ processing: false, error });
    }
  }

  async onEmailChange(email) {
    console.log('onEmailChange: ', email);
    const { setUserState = () => {} } = this.props;
    await setUserState({ email });
  }

  async setState(state) {
    return new Promise((resolve, reject) => {
      try {
        super.setState(state, resolve);
      } catch (err) {
        reject(err);
      }
    });
  }

  async setUserPassengerId(id) {
    const { setUserState = () => {} } = this.props;
    await setUserState({ passengerId: id });
  }

  // TODO: Put function to add a passenger in a shared lib?
  async setUserPassengerDetails(p) {
    const {
      appState: {
        user: { savedPassengers = {} },
        session: { passengerDetails = [] },
      },
      setSession = () => {},
      setUserState = () => {},
    } = this.props;

    // Create new passenger
    const newPassenger = Object.assign({ id: uniqid() }, p);

    // Save it and select it
    await setUserState({
      savedPassengers: Object.assign({}, savedPassengers, { [newPassenger.id]: newPassenger }),
      passengerId: newPassenger.id,
    });

    const newPassengerDetails = [...(passengerDetails || []), newPassenger];
    await setSession({
      passengerDetails: newPassengerDetails,
    });
  }

  render() {
    const { error = null, processing = false, success = false } = this.state;
    const {
      newCard = false,
      appState: {
        user: {
          loggedIn, email, cards, savedPassengers, passengerId,
        },
        session: { outboundTrip, returnTrip, passengerDetails },
      },
      history: { goBack },
    } = this.props;

    let payAmount = 0;
    if (passengerDetails && outboundTrip) {
      payAmount = passengerDetails.length * (outboundTrip.price + (returnTrip ? returnTrip.price : 0));
    }

    if (success) {
      return <Redirect to="/book/booking-confirmed/" />;
    }

    if (!error && (processing || (loggedIn && check.nonEmptyObject(cards) && !newCard))) {
      return <ProcessingPayementLoaderPageUI />;
    }

    return (
      <StripeProvider apiKey={stripeConfig.apiKey}>
        <Elements>
          <CreditCardPaymentPageUI
            payAmount={payAmount}
            error={error}
            email={email}
            loggedIn={loggedIn}
            onEmailChange={this.onEmailChange}
            onToken={this.onPaymentValidated}
            onBack={goBack}
            savedPassengers={Object.values(savedPassengers)}
            passengerId={passengerId}
            setUserPassengerDetails={this.setUserPassengerDetails}
            setUserPassengerId={this.setUserPassengerId}
          />
        </Elements>
      </StripeProvider>
    );
  }
}
