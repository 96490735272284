import React from 'react';
import check from 'check-types';
import formatNumber from 'format-number';
import ucfirstEachWord from '../../../lib/ucfirstEachWord';

import SelectedPassengers from './shared/SelectedPassengers';
import {
  DefaultScreen,
  Element,
  ElementGroup,
  Container,
  Bottom,
  Button,
  TicketDetails,
  KeyValueElement,
  SquaredText,
  HighligthedText,
  Icon,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('TicketDetailsPageUI');

const formatPrice = price => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const TicketDetailsPageUI = ({ ticket, onBack = () => {} }) => {
  const { ticketDetails } = ticket;
  const dt = ucfirstEachWord(ticketDetails.originTerminal.name);
  const at = ucfirstEachWord(ticketDetails.destinationTerminal.name);
  const title = (
    <div style={{ display: 'inline', marginLeft: 5 }}>
      {dt}
      <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
      {at}
    </div>
  );
  return (
    <DefaultScreen title={title} onBack={onBack}>
      <ElementGroup title={t('Your trip')}>
        <Element>
          <TicketDetails
            ticketDetails={ticket.ticketDetails}
            nPassengers={
              check.nonEmptyArray(ticket.ticketDetails.passengers)
                ? ticket.ticketDetails.passengers.length
                : 1
            }
          />
        </Element>
      </ElementGroup>

      <ElementGroup title={t('Passengers')}>
        <Element>
          <SelectedPassengers passengers={ticket.ticketDetails.passengers} />
        </Element>
      </ElementGroup>

      <ElementGroup title={t('Placement')}>
        <KeyValueElement
          title={t('Selected seats')}
          subtitle={t('This bus uses reserved seats')}
          value={(ticket.ticketDetails.passengers || []).map(p => (
            <SquaredText style={{ width: 24, height: 24 }}>{p.seat}</SquaredText>
          ))}
        >
          {/*
           <Button to={showSeatsLink} fluid >
             {seatsLeftToBook > 0 ? 'Select seats' : 'Change seats'}
           </Button>
           */}
        </KeyValueElement>
      </ElementGroup>

      <ElementGroup title={t('Price details')}>
        <KeyValueElement
          slim
          title={t('Ticket price')}
          value={formatPrice(ticket.ticketDetails.grossPrice)}
        />
        <KeyValueElement
          slim
          title={t('Internet booking service')}
          value={formatPrice(ticket.ticketDetails.fee)}
        />
        <KeyValueElement
          title={<b>{t('TOTAL')}</b>}
          value={<b>{formatPrice(ticket.ticketDetails.price)}</b>}
        />
      </ElementGroup>

      {/*  <ElementGroup title="Route details">
        <Element>
          <Button to={`/tickets/${ticket.id}/stops/`} fluid>
            Show route
          </Button>
        </Element>
      </ElementGroup>
      */}

      <ElementGroup>
        <Element>
          {t('Tickets are nominative.')}
          <br />
          {t("You'll need to present your")}
          {' '}
          <HighligthedText>{t('cedula or passport')}</HighligthedText>
          {' '}
          {t('along with your ticket before boarding.')}
        </Element>

        <Element>
          {t("You'll receive your tickets")}
          {' '}
          <HighligthedText>{`${t('by e-mail')}.`}</HighligthedText>
          <br />
          {t('You can present your tickets either on the Busea app or printed on paper.')}
        </Element>
      </ElementGroup>

      <Bottom>
        <Container>
          <Button to={`/tickets/${ticket.id}/boarding-pass/`} fluid primary>
            {t('Show ticket')}
          </Button>
        </Container>
      </Bottom>
    </DefaultScreen>
  );
};

export default TicketDetailsPageUI;
