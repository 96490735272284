import React from 'react';
import SignUpOkPageUI from '../../ui/pages/SignUpOkPageUI';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('SignUpOkPage');

const SignUpOkPage = (props) => {
  const {
    appState: {
      user: { tickets = [] },
    },
  } = props;

  const nextLink = tickets.length === 0 ? '/book/' : '/orders/';
  const nextLabel = tickets.length === 0 ? t('Book a trip') : t('See my tickets');

  return <SignUpOkPageUI nextLabel={nextLabel} nextLink={nextLink} />;
};
export default SignUpOkPage;
