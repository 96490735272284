import React from 'react';
import { Header, Image } from 'semantic-ui-react';
import {
  Page, Container, Bottom, Button, Title,
} from '../theme';
import { withBoldTitleFont, withDefaultFont, withDangerLinkButtonFont } from '../theme/Fonts';
import { translatorForNamespace } from '../../../lib/TranslationManager';
import whatsAppLogo from '../../containers/BetaOnboarding/assets/whatsapp.svg';
import buseaLogo from './WelcomePageUI/icon.svg';

const t = translatorForNamespace('BetaOnboardingWaitingPageUI');

const BetaOnboardingWaitingPageUI = ({ onLogout }) => (
  <Page>
    <div
      style={{
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Image width={64} src={buseaLogo} style={{ margin: 20 }} />
      <Title
        style={{
          textAlign: 'center',
          lineHeight: '1.4',
          width: '80vw',
        }}
      >
        {t('Estas a un paso de descubrir')}
        <br />
        {t('la ')}
        <strong>{t('mejor forma')}</strong>
        {t(' de comprar')}
        <br />
        {t('tus boletos de bus')}
      </Title>
      <Title
        style={{
          textAlign: 'center',
          lineHeight: '1.4',
          width: '80vw',
          marginTop: 20,
          fontWeight: 300,
        }}
      >
        {t('Mucha gente esta pidiendo inscribirse a Busea.')}
        <br />
        {t('Por favor, espere tu torno para ingresar')}
      </Title>
      {/*
        <Header style={withBoldTitleFont({ textAlign: 'center' })}>
          {t('Hay {x} personas', { x: 203 })}
          <br />
          <span
            style={{
              fontWeight: 300,
              fontSize: '1rem',
            }}
          >
            {t('delante tuyo en la fila')}
          </span>
        </Header>
        <Title
          style={{
            textAlign: 'center',
            lineHeight: '1.4',
            width: '80vw',
            marginTop: 5,
          }}
        >
          <strong>{t('{x} personas', { x: 2039 })}</strong>
          <br />
          <span
            style={{
              fontWeight: 300,
              fontSize: '1rem',
            }}
          >
            {t('son detras tuyo')}
          </span>
        </Title>

        */}

      <Title
        style={{
          textAlign: 'center',
          lineHeight: '1.4',
          width: '80vw',
          marginTop: 30,
          fontSize: '1rem',
        }}
      >
        Comparte Busea con tus amigos para ingresar mas rapido!
      </Title>
      <Button
        color="#01e675"
        style={{
          marginTop: 10,
          height: 40,
          borderRadius: 5,
          padding: 0,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        onClick={() => {
          window.location.href = `whatsapp://send?text=${encodeURIComponent(
            'Mira: Busea es una app para comprar boletos de bus desde tu movil.\nNo puedo esperar!\n\nhttps://busea.ec',
          )}`;
        }}
      >
        <Image src={whatsAppLogo} avatar />
        {t('Comparte Busea en WhatsApp')}
      </Button>
    </div>
    <Bottom>
      <Container style={{ textAlign: 'center' }}>
        <div
          style={withDangerLinkButtonFont({
            textDecoration: 'none',
            cursor: 'pointer',
          })}
          onClick={() => {
            // reactSwipeEl.next();
            onLogout();
          }}
        >
          {t('Log out')}
        </div>
      </Container>
    </Bottom>
  </Page>
);

export default BetaOnboardingWaitingPageUI;
