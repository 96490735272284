import React from 'react';
import OrdersPageUI from '../../ui/pages/OrdersPageUI';
import EmptyOrdersPageUI from '../../ui/pages/EmptyOrdersPageUI';
import { findTickets } from './lib/ordersUtils';

const OrdersPage = (props) => {
  const {
    appState: {
      user: { ordersById = {} },
    },
    match: {
      params: { orderId = null },
    },
    history,
  } = props;

  const tickets = findTickets(ordersById, { orderId });

  if (tickets.length === 0) {
    return <EmptyOrdersPageUI />;
  }

  return (
    <OrdersPageUI
      tickets={tickets}
      isFiltered={!!orderId && Object.keys(ordersById).length > 1}
      onUnfilter={() => {
        history.push('/orders/');
      }}
    />
  );
};
export default OrdersPage;
