import React from 'react';
import { Link } from 'react-router-dom';

import { withLinkButtonFont } from './Fonts';

const LinkButton = ({ style, to, children }) => (
  <Link
    to={to}
    style={Object.assign(
      withLinkButtonFont({
        textDecoration: 'none',
        cursor: 'pointer',
      }),
      style || {},
    )}
  >
    {children}
  </Link>
);

export default LinkButton;
