import React from 'react';
import formatNumber from 'format-number';
import ucfirstEachWord from '../../../lib/ucfirstEachWord';

import {
  DefaultScreen,
  Element,
  ElementGroup,
  Container,
  Bottom,
  Button,
  TicketDetails,
  SquaredText,
  KeyValueElement,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('GenericTripDetailsPageUI');

const formatPrice = price => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const GenericTripDetailsPageUI = ({
  /* origin,
  destination,
  date, */
  trip,
  isReturn,
  showSeatsLink,
  showStopsLink,
  confirmLink,
  numberOfPassengers,
  isOneWayTrip,
  onBack = () => {},
}) => {
  const dep = trip.originTerminal.name;
  const arr = trip.destinationTerminal.name;

  const title = (a, b) => `${ucfirstEachWord(a)} ⟶ ${ucfirstEachWord(b)}`;

  console.log('Displaying details for trip: ', JSON.stringify(trip, null, 4));

  const nSelectedSeats = (trip.selectedSeats || []).length;
  const seatsLeftToBook = numberOfPassengers - nSelectedSeats;

  let tripName = 'trip';
  if (!isOneWayTrip) {
    tripName = isReturn ? t('return trip') : t('outbound trip');
  }

  const readableTicketPrice = (numberOfPassengers > 1 ? `${numberOfPassengers}x ` : '') + formatPrice(trip.grossPrice);

  const ticketTitle = (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <span>{`${isReturn ? t('Return trip') : t('Outbound trip')}`}</span>
      <span>{readableTicketPrice}</span>
    </div>
  );

  return (
    <DefaultScreen
      title={t('{CityXtoCityY} trip', { CityXtoCityY: title(dep, arr) })}
      onBack={onBack}
    >
      <ElementGroup title={ticketTitle}>
        <Element>
          <TicketDetails ticketDetails={trip} nPassengers={numberOfPassengers} />
        </Element>
      </ElementGroup>
      <ElementGroup title={t('Price details')}>
        <KeyValueElement
          slim
          title={t('Ticket price')}
          value={formatPrice(numberOfPassengers * trip.grossPrice)}
        />
        <KeyValueElement
          slim
          title={t('Internet booking service')}
          value={formatPrice(numberOfPassengers * trip.fee)}
        />
        <KeyValueElement
          title={<b>{t('TOTAL')}</b>}
          value={<b>{formatPrice(numberOfPassengers * trip.price)}</b>}
        />
      </ElementGroup>

      <ElementGroup title={t('Route details')}>
        <Element>
          <Button to={showStopsLink} fluid>
            {t('Show route')}
          </Button>
        </Element>
      </ElementGroup>

      <ElementGroup title={t('Placement')}>
        <KeyValueElement
          title={nSelectedSeats === 0 ? t('No seats selected yet') : t('Selected seats')}
          subtitle={t('This bus uses reserved seats')}
          value={(trip.selectedSeats || []).map(s => (
            <SquaredText style={{ width: 24, height: 24 }}>{s}</SquaredText>
          ))}
        >
          <Button to={showSeatsLink} fluid primary={seatsLeftToBook > 0}>
            {seatsLeftToBook > 0 ? t('Select seats') : t('Change seats')}
          </Button>
        </KeyValueElement>
      </ElementGroup>

      {seatsLeftToBook === 0 && (
        <Bottom>
          <Container>
            <Button to={confirmLink} fluid primary>
              {`${t('Confirm')} ${tripName}`}
            </Button>
          </Container>
        </Bottom>
      )}
    </DefaultScreen>
  );
};

export default GenericTripDetailsPageUI;
