const blackBeans = '#2D0E44';
const snowBis = '#E5E7EF';
const pastelBeans = '#B399D4';

export const backgroundColor = '#F3F4F8';
export const primaryColor = '#00C3A8';
export const secondaryColor = '#21304D';
export const textColor = '#343F43';
export const discreetTextColor = '#95A3A7';
export const grayElementColor = '#999EA1';
export const widgetBorderColor = '#DDE2E6';
export const widgetBackgroundColor = '#FFFFFF';
export const onDarkTextColor = '#FFFFFF';
export const topBarBgColor = secondaryColor;
export const topBarSubBgColor = '#172236';
export const topBarIdleTabColor = '#BCC0C9';
export const defaultButtonColor = '#8A9EA1';
export const emphasisColor = '#4D95F8';
export const secondaryButtonColor = '#30A9DE';
export const textHighlightColor = '#FFE84A';
export const dangerColor = '#dd4b39';
