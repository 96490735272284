import React from 'react';
import { Image } from 'semantic-ui-react';
import animation from './animation.gif';

const ProcessingPayementLoaderPageUI = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#D24000',
    }}
  >
    <Image src={animation} fluid />
  </div>
);

export default ProcessingPayementLoaderPageUI;
