import React from 'react';

import { withWidgetTitleFont } from './Fonts';

const Title = ({ style = {}, children }) => (
  <div
    style={Object.assign(
      withWidgetTitleFont({
        paddingLeft: '5%',
        paddingRight: '5%',
      }),
      style,
    )}
  >
    {children}
  </div>
);

export default Title;
