import React, { Component } from 'react';
import BookingFormPageUI from '../../ui/pages/BookingFormPageUI';

export default class BookingFormPage extends Component {
  constructor(...args) {
    super(...args);

    // Bindings
    this.onFormChange = this.onFormChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  async onFormSubmit(formResults) {
    const { history } = this.props;
    const {
      selectedDeparture, selectedArrival, numberOfPassengers, outDate,
    } = formResults;

    await this.onFormChange(formResults);

    if (selectedDeparture && selectedArrival && numberOfPassengers && outDate) {
      history.push('/book/out/');
    }
  }

  async onFormChange(formResults) {
    const { setSession = () => {} } = this.props;
    const {
      appState: { session },
    } = this.props;
    const {
      selectedDeparture, selectedArrival, outDate, returnDate,
    } = formResults;

    let { numberOfPassengers } = formResults;

    // Number of passengers can't be < 1
    if (numberOfPassengers < 1) {
      numberOfPassengers = 1;
    }

    // Clear passenger details if we change the number of passengers
    if (session.numberOfPassengers !== numberOfPassengers) {
      await setSession({ passengerDetails: [] });
    }
    // Clear outboundTrip if we change departure city or departure date
    if (session.selectedDeparture !== selectedDeparture || session.outDate !== outDate) {
      await setSession({ outboundTrip: null });
    }
    // Clear returnTrip if we change arrival city or return date
    if (session.selectedArrival !== selectedArrival || session.returnDate !== returnDate) {
      await setSession({ returnTrip: null });
    }

    // Save results in session
    await setSession({
      selectedDeparture,
      selectedArrival,
      numberOfPassengers,
      outDate,
      returnDate,
    });
  }

  render() {
    const {
      appState: {
        session: {
          selectedDeparture, selectedArrival, numberOfPassengers, outDate, returnDate,
        },
        cache: { routes = {}, locations = {} },
      },
    } = this.props;

    // Generate departures and arrivals
    const departuresKeys = Object.keys(routes);
    departuresKeys.sort();

    let arrivalKeys = Object.keys(locations);
    if (selectedDeparture) {
      arrivalKeys = routes[selectedDeparture] ? Object.values(routes[selectedDeparture]) : [];
    }
    arrivalKeys.sort();

    const departures = departuresKeys
      .map((id) => {
        if (!locations[id]) {
          console.warn(`Location '${id}' doesn't exist`);
          return null;
        }
        return {
          key: `dep_${id}`,
          value: id,
          text: locations[id].displayName,
        };
      })
      .filter(d => !!d);
    const arrivals = arrivalKeys
      .map((id) => {
        if (!locations[id]) {
          console.warn(`Location '${id}' doesn't exist`);
          return null;
        }
        return {
          key: `arr_${id}`,
          value: id,
          text: locations[id].displayName,
        };
      })
      .filter(a => !!a);

    return (
      <BookingFormPageUI
        {...{
          selectedDeparture,
          selectedArrival,
          numberOfPassengers,
          outDate,
          returnDate,
          locations,
          routes,
          departures,
          arrivals,
        }}
        onChange={this.onFormChange}
        onSubmit={this.onFormSubmit}
      />
    );
  }
}
