import React from 'react';
import GenericTripsListPage from './shared/GenericTripsListPage';

const OutTripsListPage = ({
  appState: {
    session: {
      selectedDeparture, selectedArrival, outDate, outboundTrip, numberOfPassengers,
    },
    cache: { rides = {} },
  },
  setSession = () => {},
  history,
}) => (
  <GenericTripsListPage
    origin={selectedDeparture}
    destination={selectedArrival}
    date={outDate}
    ridesCache={rides}
    isReturn={false}
    selectedTrip={outboundTrip}
    onTripSelect={async (trip) => {
      await setSession({ outboundTrip: trip });
      history.push('/book/out/select/');
    }}
    history={history}
    numberOfPassengers={numberOfPassengers}
  />
);

export default OutTripsListPage;
