import React, { Fragment } from 'react';
import check from 'check-types';

import {
  LoadingPlaceHolder, PopupScreen, Container, Bottom, Button, TitleBlock,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('GenericTripSeatSelectionPageUI');

const busyColor = '#999EA1'; // '#8275FA';
const freeColor = '#E9E9E9';
const selectedColor = '#8275FA'; // '#26B240';
const SeatRowComponent = ({
  seats,
  width,
  margin,
  selectedSeats = [],
  onSeatToggle = null,
  numberOfSeatsToSelect = 0,
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
    }}
  >
    {seats.map((s, i) => {
      const seatFree = !!s.asb_numasiento && s.asi_disp;
      const visibility = s.asb_numasiento ? 'visible' : 'hidden';
      const showRightSpace = index => index % 2 === 1 && index + 1 < seats.length;
      const seatSelected = selectedSeats && selectedSeats.includes(s.asb_numasiento);
      let fontWeight = 'normal';
      let seatColor = freeColor;
      let color = 'gray';
      let cursor = onSeatToggle ? 'pointer' : 'inherit';

      const selectable = check.function(onSeatToggle)
        && ((seatFree && selectedSeats.length < numberOfSeatsToSelect)
          || selectedSeats.includes(s.asb_numasiento));

      if (!seatFree) {
        seatColor = busyColor;
        color = 'white';
        fontWeight = 'bold';
      }
      if (seatSelected) {
        seatColor = selectedColor;
        color = 'white';
        fontWeight = 'bold';
      }
      if (onSeatToggle && !selectable) {
        cursor = 'not-allowed';
      }
      return (
        <Fragment>
          <div
            key={`seat_${i}_${s.asb_numasiento}`}
            style={{
              margin,
              width,
              height: width,
              borderRadius: 5,
              backgroundColor: seatColor,
              visibility,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color,
              cursor,
              fontWeight,
            }}
            onClick={
              selectable
                ? () => {
                  onSeatToggle(s.asb_numasiento);
                }
                : undefined
            }
          >
            {s.asb_numasiento}
          </div>
          {showRightSpace(i) && (
            <div
              key={`seat_${i}_space`}
              style={{
                margin,
                marginLeft: 0,
                marginRight: 0,
                width,
                height: width,
                borderRadius: 5,
                backgroundColor: seatFree ? freeColor : busyColor,
                visibility: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: seatFree ? 'gray' : 'white',
              }}
            >
              {s.asb_numasiento}
            </div>
          )}
        </Fragment>
      );
    })}
  </div>
);

const SeatMatrixComponent = ({
  seatMatrix,
  selectedSeats = [],
  onSeatToggle = null,
  numberOfSeatsToSelect = 0,
}) => {
  const width = `${Math.round((0.8 * 100) / (2 * seatMatrix.length))}vh`;
  const margin = `${Math.round((0.8 * 100) / (9 * seatMatrix.length))}vh`;
  return (
    <div
      style={{
        padding: margin,
        margin: '0vh',
        backgroundColor: '#F6F6F6',
        borderRadius: '10px',
        width: 'fit-content',
        fontSize: '0.8em',
      }}
    >
      {seatMatrix.map((row, i) => (
        <SeatRowComponent
          key={`row_${i}`}
          seats={row}
          width={width}
          margin={margin}
          selectedSeats={selectedSeats}
          onSeatToggle={onSeatToggle}
          numberOfSeatsToSelect={numberOfSeatsToSelect}
        />
      ))}
    </div>
  );
};

const GenericTripSeatSelectionPageUI = ({
  /*
   error,
  selectedTrip,
  */
  seatMatrix,
  loading,
  /*
  isReturn,
  */
  selectedSeats = [],
  onSeatToggle = null,
  numberOfSeatsToSelect = 0,
  onBack = () => {},
}) => {
  let content = null;

  if (loading) {
    content = <LoadingPlaceHolder />;
  } else {
    content = (
      <div
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SeatMatrixComponent
          seatMatrix={seatMatrix}
          selectedSeats={selectedSeats}
          onSeatToggle={onSeatToggle}
          numberOfSeatsToSelect={numberOfSeatsToSelect}
        />
      </div>
    );
  }

  const seatsLeft = numberOfSeatsToSelect - selectedSeats.length;
  let title = t('{n} seats left to select', { n: seatsLeft });
  let subtitle = t('Click on an empty seat to select it');
  if (seatsLeft === 0) {
    title = t('All seats selected');
    subtitle = t('You can unselect seats by clicking on them');
  }

  return (
    <PopupScreen title={t('Select seats')} onBack={onBack} fullScreen>
      <TitleBlock title={title} subtitle={subtitle} />

      {content}
      <Bottom noSpacer>
        <Container>
          <Button onClick={onBack} fluid primary disabled={seatsLeft !== 0}>
            {t('Book seats')}
          </Button>
        </Container>
      </Bottom>
    </PopupScreen>
  );
};

export default GenericTripSeatSelectionPageUI;
