import React, { Fragment, Component } from 'react';
import { Redirect } from 'react-router-dom';
import check from 'check-types';
import firstMatch from 'first-match';
import ExpandedRideListener from '../../listeners/ExpandedRideListener';
import GenericStopsPageUI from '../../../ui/pages/GenericStopsPageUI';

export default class GenericTripStopsPage extends Component {
  constructor(...args) {
    super(...args);

    // Bindings
    this.onBack = this.onBack.bind(this);
  }

  onBack() {
    const { history } = this.props;
    history.goBack();
  }

  getStops() {
    const {
      origin, destination, date, selectedTrip = null, ridesCache,
    } = this.props;

    const path = `${origin}/${destination}/${date}`;
    const rides = ridesCache[path] || [];

    console.log('GenericTripStopsPage: selectedTrip ==> ', selectedTrip);
    console.log('GenericTripStopsPage: rides ==> ', rides);
    const ride = firstMatch(rides, r => r.id === selectedTrip.id);
    const stops = ride ? ride.stops || [] : null;

    return stops;
  }

  render() {
    const {
      origin, destination, date, selectedTrip = null, isReturn = false,
    } = this.props;

    if (!origin || !destination || !date) {
      return <Redirect to="/book/" />;
    }
    if (!selectedTrip) {
      return <Redirect to={`/book/${isReturn ? 'return' : 'out'}/`} />;
    }

    const stops = this.getStops();

    return (
      <Fragment>
        <GenericStopsPageUI
          {...{
            loading: !check.nonEmptyArray(stops),
            ticket: selectedTrip,
            stops,
            onBack: this.onBack,
          }}
        />
      </Fragment>
    );
  }
}
