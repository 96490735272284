import React from 'react';

import SignInOrSignUpPageUI from './shared/SignInOrSignUpPageUI';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('LoginPageUI');

const LoginPageUI = ({
  error,
  email,
  password,
  onEmailChange = () => {},
  onPasswordChange = () => {},
  onSubmit = () => {},
  onFacebookSignIn = () => {},
  onGoogleSignIn = () => {},
  onBack = () => {},
}) => (
  <SignInOrSignUpPageUI
    {...{
      error,
      email,
      password,
      onEmailChange,
      onPasswordChange,
      onSubmit,
      onFacebookSignIn,
      onGoogleSignIn,
      title: t('Login'),
      dividerText: t('or login with'),
      onBack,
    }}
  />
);

export default LoginPageUI;
