import React from 'react';
import { Page, HomeTopBar, PlaceHolder } from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('EmptyOrdersPageUI');

const EmptyOrdersPageUI = () => (
  <Page>
    <HomeTopBar selectedTab="orders" />
    <PlaceHolder icon="ticket" title={t('No upcoming trip')} />
  </Page>
);

export default EmptyOrdersPageUI;
