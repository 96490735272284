import React from 'react';
import Loader from 'react-loader-spinner';
import { Image } from 'semantic-ui-react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { primaryColor } from './Colors';
import loadingImage from './assets/loading-image.gif';

const LoadingPlaceHolder = () => (
  <div
    style={{
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <Image src={loadingImage} size="small" verticalAlign="middle" />
    <Loader type="ThreeDots" color={primaryColor} width="60" style={{ marginTop: -20 }} />
  </div>
);

export default LoadingPlaceHolder;
