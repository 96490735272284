import React from 'react';
import { backgroundColor, primaryColor } from './Colors';
import { withDefaultFont } from './Fonts';

const Page = ({ fullScreen = false, children }) => {
  const style = withDefaultFont({
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor,
    color: primaryColor,
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  });

  if (fullScreen) {
    Object.assign(style, {
      width: '100vw',
      height: '100vh',
    });
  }

  return <div style={style}>{children}</div>;
};

export default Page;
