import React from 'react';
import GenericTripStopsPage from './shared/GenericTripStopsPage';

const OutTripStopsPage = ({ appState, history }) => {
  console.log('OutTripStopsPage: appState ==> ', appState);

  const {
    session: {
      selectedDeparture, selectedArrival, outDate, outboundTrip,
    },
    cache: { rides = [] },
  } = appState;

  return (
    <GenericTripStopsPage
      origin={selectedDeparture}
      destination={selectedArrival}
      date={outDate}
      selectedTrip={outboundTrip}
      isReturn={false}
      ridesCache={rides}
      history={history}
    />
  );
};

export default OutTripStopsPage;
