import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import WelcomePageUI from '../../ui/pages/WelcomePageUI';

export default class WelcomePage extends Component {
  async componentDidMount() {
    const { setSession = () => {} } = this.props;
    await setSession({ hasSeenWelcomeScreen: true });
  }

  render() {
    const {
      appState: {
        user: { loggedIn },
      },
      history,
    } = this.props;

    if (loggedIn) {
      return <Redirect to="/book/" />;
    }

    return (
      <WelcomePageUI
        connectLink="/login/"
        registerLink="/create-account/"
        bookLink="/book/"
        onBack={() => {
          history.goBack();
        }}
      />
    );
  }
}
