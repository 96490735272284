import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import ExpandedRideListener from '../listeners/ExpandedRideListener';
import GenericTripDetailsPageUI from '../../ui/pages/GenericTripDetailsPageUI';

const ReturnTripDetailsPage = ({
  appState: {
    session: {
      selectedDeparture, selectedArrival, returnDate, returnTrip, numberOfPassengers,
    },
  },
  history,
}) => {
  if (!returnTrip) {
    return <Redirect to="/book/passenger-details/" />;
  }

  return (
    <Fragment>
      <ExpandedRideListener rideId={returnTrip.id} />
      <GenericTripDetailsPageUI
        origin={selectedArrival}
        destination={selectedDeparture}
        date={returnDate}
        trip={returnTrip}
        isReturn
        isOneWayTrip={!returnDate}
        numberOfPassengers={numberOfPassengers}
        showSeatsLink="/book/return/select/seats/"
        showStopsLink="/book/return/select/stops/"
        confirmLink="/book/passenger-details/0/"
        onBack={history.goBack}
      />
    </Fragment>
  );
};

export default ReturnTripDetailsPage;
