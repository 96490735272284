import React, { Component } from 'react';
import check from 'check-types';
import { Form, Input } from 'semantic-ui-react';
import PhoneInput from '../../vendors/react-phone-input-2-master/src';
import '../../vendors/react-phone-input-2-master/src/style.css';
import {
  Element, ElementGroup, PopupScreen, Bottom, Button, Container,
} from '../theme';

import { COUNTRY_OPTIONS } from '../../../config/countriesData';

import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('AddPassengerPopupUI');

class AddPassengerPopupUI extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      firstName: '',
      lastName: '',
      ssn: '',
      country: null,
      phone: '',
    };
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  isCedulaValid() {
    const { ssn, country } = this.state;

    if (country === 'ec') {
      return /^[0-9]{10}$/.test(ssn);
    }

    return /^[0-9A-Z-]+$/.test(ssn);
  }

  isDataValid() {
    const {
      firstName, lastName, ssn, country,
    } = this.state;

    if (!check.nonEmptyString(firstName.trim())) {
      return false;
    }
    if (!check.nonEmptyString(lastName.trim())) {
      return false;
    }
    if (!country || !check.nonEmptyString(country.trim())) {
      return false;
    }
    if (!check.nonEmptyString(ssn.trim())) {
      return false;
    }

    return this.isCedulaValid();
  }

  render() {
    const { onAdd = () => {}, onBack = () => {} } = this.props;
    const {
      firstName, lastName, ssn, country, phone,
    } = this.state;

    return (
      <PopupScreen title={t('Add passenger')} onBack={onBack}>
        <ElementGroup title={t('Passenger name')} subtitle={t('Will display on your tickets')}>
          <Element>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                placeholder={t('First name')}
                value={firstName || ''}
                onChange={(e, o) => this.onChange('firstName', o.value)}
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <Form.Input
                fluid
                placeholder={t('Last name')}
                value={lastName || ''}
                onChange={(e, o) => this.onChange('lastName', o.value)}
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
            </Form.Group>
          </Element>
        </ElementGroup>

        <ElementGroup title={t('Phone number')} subtitle={t('Used to send ticket information')}>
          <Element>
            <Form.Group widths="equal">
              <PhoneInput
                placeholder={t('Mobile phone')}
                defaultCountry="ec"
                value={phone}
                onChange={(v, { countryCode }) => {
                  this.onChange('phone', v);
                  const { country: c } = this.state;
                  if (!c) {
                    this.onChange('country', countryCode);
                  }
                }}
                inputStyle={{
                  width: '100%',
                  height: 38,
                  marginTop: 10,
                  marginBottom: 10,
                  borderColor: '#22242626',
                  borderRadius: '5px',
                }}
              />
            </Form.Group>
          </Element>
        </ElementGroup>

        <ElementGroup
          title={t('Identification document')}
          subtitle={t('Necessary to onboard the bus')}
        >
          <Element>
            <Form.Dropdown
              placeholder={t('Country')}
              fluid
              search
              selection
              options={COUNTRY_OPTIONS}
              value={country || 'ec'}
              onChange={(e, o) => this.onChange('country', o.value)}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Input
              error={check.nonEmptyString(ssn) && !this.isCedulaValid()}
              fluid
              placeholder={!country || country === 'ec' ? t('Cedula') : t('Passport number')}
              value={ssn || ''}
              onChange={(e, o) => this.onChange('ssn', o.value)}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </Element>
        </ElementGroup>
        <Bottom>
          <Container style={{ display: 'flex' }}>
            <Button onClick={onBack} style={{ flex: '1', margin: 5 }}>
              {t('Back')}
            </Button>
            <Button
              onClick={() => onAdd(this.state)}
              style={{ flex: '1', margin: 5 }}
              primary
              disabled={!this.isDataValid()}
            >
              {t('Add')}
            </Button>
          </Container>
        </Bottom>
      </PopupScreen>
    );
  }
}

export default AddPassengerPopupUI;
