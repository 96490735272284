import React from 'react';
import MobileCalendar from 'react-scroll-calendar';
import 'react-scroll-calendar/build/react-scroll-calendar.css';
import { Element, ElementGroup, DefaultScreen } from '../theme';

import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('DateSelectionPopupUI');

const DateSelectionPopupUI = ({
  title,
  date,
  minDate,
  maxDate,
  onChange = () => {},
  onBack = () => {},
}) => (
  <DefaultScreen title={title} onBack={onBack}>
    {/*
    <ElementGroup title="Who's in this trip">
      <Element>
        <Input value="Date selected here" />
      </Element>
    </ElementGroup>
    */}

    <ElementGroup style={{ marginTop: 0 }} title={t('Select a date')}>
      <Element>
        <MobileCalendar
          onSelect={onChange}
          minDate={minDate}
          selectedDate={date}
          maxDate={maxDate}
        />
      </Element>
    </ElementGroup>
  </DefaultScreen>
);

export default DateSelectionPopupUI;
