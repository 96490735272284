import React from 'react';

const Container = ({ style, children }) => (
  <div
    style={Object.assign(
      {
        width: '100%',
        paddingLeft: '5%',
        paddingRight: '5%',
      },
      style,
    )}
  >
    {children}
  </div>
);

export default Container;
