import React from 'react';
import { Redirect } from 'react-router-dom';
import GenericTripsListPage from './shared/GenericTripsListPage';

const ReturnTripsListPage = ({
  appState: {
    session: {
      selectedDeparture, selectedArrival, returnDate, returnTrip, numberOfPassengers,
    },
    cache: { rides = {} },
  },
  setSession = () => {},
  history,
}) => {
  if (!returnDate) {
    return <Redirect to="/book/passenger-details/" />;
  }

  return (
    <GenericTripsListPage
      origin={selectedArrival}
      destination={selectedDeparture}
      date={returnDate}
      ridesCache={rides}
      isReturn
      selectedTrip={returnTrip}
      onTripSelect={async (trip) => {
        await setSession({ returnTrip: trip });
        history.push('/book/return/select/');
      }}
      history={history}
      numberOfPassengers={numberOfPassengers}
    />
  );
};

export default ReturnTripsListPage;
