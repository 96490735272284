import React from 'react';

import { DefaultScreen, PlaceHolder } from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('TicketNotFoundPageUI');

const TicketNotFoundPageUI = ({ onBack = () => {} }) => (
  <DefaultScreen title={t('Ticket not found')} onBack={onBack}>
    <PlaceHolder icon="ticket" title={t('Ticket not found')} />
  </DefaultScreen>
);

export default TicketNotFoundPageUI;
