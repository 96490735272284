import React from 'react';

import {
  DefaultScreen,
  Element,
  ElementGroup,
  Button,
  Container,
  BlockWithLeftIcon,
  Bottom,
} from '../theme';

import tickPic from '../theme/assets/tick.svg';
import userPic from '../theme/assets/boy.svg';

import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('BookingConfirmedUI');

const BookingConfirmedUI = ({
  /* outboundTrip, returnTrip, */ createAccountLink,
  viewTicketsLink,
  loggedIn,
}) => (
  <DefaultScreen title={t('Order confirmed')}>
    <ElementGroup title={t('Your payment was successful')}>
      <Element>
        <BlockWithLeftIcon icon={tickPic}>
          {t('Your order is validated')}
          <br />
          <br />
          {t('We sent you an e-mail with your order details and your bus tickets.')}
          <br />
          <br />
          {t('Have a nice trip!')}
        </BlockWithLeftIcon>
      </Element>
    </ElementGroup>

    {!loggedIn && (
      <ElementGroup title={t('Save some time next time')}>
        <Element>
          <BlockWithLeftIcon icon={userPic}>
            {t('Be smart:')}
            <br />
            <br />
            {t('Save your payment and passenger details by creating a free Busea account.')}
          </BlockWithLeftIcon>
          <div style={{ textAlign: 'center', marginTop: 15 }}>
            <Button to={createAccountLink} primary fluid>
              {t('Create your free account')}
            </Button>
          </div>
        </Element>
      </ElementGroup>
    )}
    <Bottom>
      <Container>
        <Button to={viewTicketsLink} primary={loggedIn} fluid>
          {t('View my tickets')}
        </Button>
      </Container>
    </Bottom>
  </DefaultScreen>
);

export default BookingConfirmedUI;
