import React, { Fragment } from 'react';
import moment from 'moment';
import check from 'check-types';
import formatNumber from 'format-number';
import { Icon, Image } from 'semantic-ui-react';
import ucfirstEachWord from '../../../lib/ucfirstEachWord';

import {
  DefaultScreen,
  Element,
  ElementGroup,
  LoadingPlaceHolder,
  PlaceHolder,
  discreetTextColor,
  SquaredText,
} from '../theme';
import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('GenericTripListPageUI');

const formatPrice = price => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const GenericTripListPageUI = (props) => {
  const {
    origin,
    destination,
    date,
    /* selectedTrip = null, */
    rides,
    isReturn,
    onTripSelect = () => {},
    onBack = () => {},
    numberOfPassengers,
  } = props;

  let content = null;

  if (!rides) {
    content = <LoadingPlaceHolder />;
  } else if (check.emptyArray(rides)) {
    content = <PlaceHolder title={t('No rides found for this trip')} icon="bus" />;
  } else {
    // Sort rides by departure time
    rides.sort((a, b) => {
      const da = moment(a.departureTime);
      const db = moment(b.departureTime);

      return da.valueOf() - db.valueOf();
    });

    // Display them
    content = (
      <Fragment>
        {rides.map((r) => {
          const departure = moment.utc(r.departureTime);
          const arrival = moment.utc(r.arrivalTime);
          const rideDuration = moment.duration(arrival.diff(departure));
          const formattedDuration = `${rideDuration.hours()}h${
            rideDuration.minutes() > 0 ? ` ${rideDuration.minutes()}m` : ''
          }`;
          const nDaysAdded = moment
            .duration(
              moment
                .utc(arrival.format('YYYY-MM-DD'))
                .diff(moment.utc(departure.format('YYYY-MM-DD'))),
            )
            .days();
          const d = nDaysAdded ? ` (+${nDaysAdded}d)` : '';
          const dh = departure.format('HH:mm');
          const ah = arrival.format('HH:mm');
          const FormattedPrice = numberOfPassengers > 1
            ? () => (
              <span>
                {numberOfPassengers}
                    &nbsp;x&nbsp;
                {formatPrice(r.grossPrice)}
              </span>
            )
            : () => formatPrice(r.grossPrice);

          return (
            <ElementGroup slim>
              <Element
                slimmer
                key={r.id}
                onClick={() => {
                  onTripSelect(r);
                }}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 0,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#f9f9f9',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingLeft: '5%',
                      paddingRight: '5%',
                      paddingTop: 10,
                      paddingBottom: 10,
                      color: 'black',
                    }}
                  >
                    <Image src={r.transporter.logo} avatar style={{ marginRight: 10 }} />
                    <div style={{ flex: '1' }}>{ucfirstEachWord(r.transporter.name)}</div>
                    <div
                      style={{
                        color: '#dedede',
                        fontSize: '1.5rem',
                        marginLeft: 5,
                        textAlign: 'right',
                      }}
                    >
                      <Icon style={{ display: 'inline', margin: 0 }} name="angle right" />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    marginTop: 15,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ fontSize: '1rem', width: '45%' }}>
                    {dh}
                    <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
                    {ah + d}
                  </div>

                  <div style={{ color: discreetTextColor, fontSize: '0.8rem', flex: '1' }}>
                    {formattedDuration}
                  </div>

                  <div>
                    <div
                      size="mini"
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1.1rem',
                        textTransform: 'none',
                        textAlign: 'right',
                      }}
                    >
                      <FormattedPrice />
                    </div>
                    {' '}
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    marginTop: 5,
                    marginBottom: 10,
                    color: discreetTextColor,
                    fontSize: '0.8rem',
                  }}
                >
                  <div>
                    {`${t('Service')}:`}
                    {' '}
                    <SquaredText style={{ fontSize: '0.6rem' }}>{r.apiData.trp_desc}</SquaredText>
                  </div>
                </div>
              </Element>
            </ElementGroup>
          );
        })}
      </Fragment>
    );
  }

  const title = (
    <div style={{ display: 'inline', marginLeft: 5 }}>
      {`${isReturn ? t('Return') : t('Outbound')}: `}
      {origin}
      <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
      {destination}
    </div>
  );

  return (
    <DefaultScreen
      title={title}
      onBack={onBack}
      topBarRightChildren={
        <span style={{ marginRight: 20 }}>{moment(date).format('DD MMM YYYY')}</span>
      }
    >
      {content}
    </DefaultScreen>
  );
};

export default GenericTripListPageUI;
